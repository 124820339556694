import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CataleanStorageService {
  public readonly STORAGE_RESOURCE_MAP_ITEM_KEY = 'assets';

  constructor() {}

  getLanguage(): Promise<string> {
    return this.getRow('locale');
  }

  async setLanguage(value: string) {
    return await this.setRow('locale', value);
  }

  getResource(remoteResourceUrl: string): Promise<string> {
    return this.getRow(`${this.STORAGE_RESOURCE_MAP_ITEM_KEY}.${remoteResourceUrl}`);
  }

  async removeAllAssetResources() {
    Preferences.keys();
    const res = await Preferences.keys();
    for (const key of res.keys) {
      if (key.includes(this.STORAGE_RESOURCE_MAP_ITEM_KEY)) {
        await Preferences.remove({ key });
      }
    }
    return true;
  }

  async getAllResources(): Promise<Record<string, any>> {
    const localResourcesMap: Record<string, unknown> = {};
    const res = await Preferences.keys();
    const resourceKeys = res.keys.filter(key => key.startsWith(this.STORAGE_RESOURCE_MAP_ITEM_KEY));

    const values = await Promise.all(resourceKeys.map(key => this.getRow(key)));

    for (let i = 0; i < resourceKeys.length; i++) {
      const key = resourceKeys[i];
      const cleanKey = key.replace(`${this.STORAGE_RESOURCE_MAP_ITEM_KEY}.`, '');
      try {
        localResourcesMap[cleanKey] = JSON.parse(values[i]);
      } catch {
        localResourcesMap[cleanKey] = values[i];
      }
    }
    return localResourcesMap;
  }

  async addOrUpdateResource(remoteResourceUrl: string, resourceObjAsString: string) {
    await this.setRow(`${this.STORAGE_RESOURCE_MAP_ITEM_KEY}.${remoteResourceUrl}`, resourceObjAsString);
  }

  async setRow(key: string, value: any) {
    if (typeof value === 'string') {
      return await Preferences.set({key, value})
    }
    return await Preferences.set({key, value: JSON.stringify(value)})
  }

  async getRow<T = string>(key: string): Promise<T> {
    const rowData = await Preferences.get({key})
    if (rowData && typeof rowData.value === 'string') {
      try {
        return JSON.parse(rowData.value);
      } catch {
        if(rowData.value === 'undefined') {
          return undefined
        }
        return rowData.value as T;
      }
    }
    return '' as T;
  }
}
