export enum StructureFieldType {
  TEXT = 'text',
  BOOLEAN = 'boolean',
  CONTAINER = 'container',
  ATTACHMENT = 'attachment',
  FILE = 'file',
  DATE = 'date',
  SELECT = 'select', // ONLY FOR User Structure Fields
  PRIVACY = 'privacy', // ONLY FOR User Structure Fields
  CALCULATED = 'calculated', // ONLY FOR User Structure Fields
  IMAGE_MAP_CONTAINER = 'imageMapContainer',
  RELATED_ENTITIES = 'relatedEntities',
  FEATURE_VALUES_CONTAINER = 'featureValuesContainer',
}

export enum StructureType {
  PRODUCT = 'product',
  WISHLIST = 'wishlist',
  COMMUNICATION = 'communication',
  COCKPIT = 'cockpit',
  BOOKABLE_RESOURCE = 'bookableResource',
}
