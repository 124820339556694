import {Injectable} from '@angular/core';
import {Resource} from 'catalean-models';

@Injectable({
  providedIn: 'root'
})
export class ResourceManagerService {

  constructor() {
  }

  private resources: Resource[] = [];

  get Resources(): Resource[] {
    return this.resources;
  }

  addResource(resource: Resource) {
    this.resources.push(resource);
  }

  mapAndSaveResources(resourcesArray: Resource[]) {
    this.resources = [...resourcesArray];
  }
}
