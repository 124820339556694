import { Injectable } from '@angular/core';
import { ActiveFilter, Filter, MetroFilter } from 'catalean-models';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, shareReplay, first, skipWhile, switchMap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterManagerService {
  //#region RootFilter
  private rootFilter$ = new BehaviorSubject<Filter>(undefined);
  getRootFilter(): Observable<Filter> {
    return this.rootFilter$.asObservable();
  }

  getRootFilterChildren(): Observable<Filter[]> {
    return this.rootFilter$.pipe(
      skipWhile((rf) => !rf),
      map((rf) => rf.children)
    );
  }

  setRootFilter(rootFilter: Filter) {
    this.rootFilter$.next(rootFilter);
    this.setSelectedMetroFilter(rootFilter);
  }
  //#endregion

  //#region MetroFilters
  private metroFilters$: Observable<MetroFilter[]> = this.rootFilter$.pipe(
    filter(filter => !!filter),
    map((filter) => this.flattenMetroFilter(filter)),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  private selectedMetroFilter$ = new BehaviorSubject<MetroFilter>(undefined);

  public getCurrentMetroPath(): Observable<string> {
    return combineLatest([this.metroFilters$, this.selectedMetroFilter$]).pipe(
      map(([metroFilters, selectedMetroFilter]) => {
        const getPath = (flattenedTree: MetroFilter[], targetFeatureId: string): string => {
          const targetNode = flattenedTree.find((node) => node.filter.featureId === targetFeatureId);
          if (!targetNode) {
            return '';
          }
          if (targetNode.level === 0) {
            return '';
          }
          const parentFilter = flattenedTree.find((node) => node.filter.children.some((f) => f.featureId === targetFeatureId));
          const parentPath = getPath(flattenedTree, parentFilter.filter.featureId);
          if (parentPath) {
            return `${parentPath}/${targetNode.filter.label}`;
          }
          return targetNode.filter.label;
        };
        const path = getPath(metroFilters, selectedMetroFilter.filter.featureId);
        return path;
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  public setSelectedMetroFilter(inputFilter: Filter | string) {
    this.metroFilters$
      .pipe(
        first(),
        map((metroFilters) =>
          metroFilters.find((mf) => {
            if (typeof inputFilter === 'string') {
              return mf.name === inputFilter;
            }
            if (!inputFilter) {
              return mf.level === 0;
            }
            return mf.name === inputFilter.name;
          })
        )
      )
      .subscribe((mf) => {
        this.resetActiveVerticalFilters();
        this.resetHorizontalFilters();
        this.selectedMetroFilter$.next(mf);
      });
  }

  private flattenMetroFilter(filter: Filter): MetroFilter[] {
    const result: MetroFilter[] = [];
    function visit(node: Filter, level: number) {
      // create a new MetroFilter object with the current node data
      const metroFilter: MetroFilter = {
        level,
        name: node.name,
        filter: node,
      };
      // add it to the result array if type is metro
      if (metroFilter.filter.align === 'metro') {
        result.push(metroFilter);
        // if the node has children, visit them recursively with increased level
        if (node.children) {
          for (const child of node.children) {
            visit(child, level + 1);
          }
        }
      }
    }
    // start visiting from the root node with level 0
    visit(filter, 0);
    console.warn(
      'called flattenMetroFilter, this should not happens more than once because it should be cached since the results never change'
    );

    return result;
  }

  getSelectedMetroFilter(): Observable<MetroFilter> {
    return this.selectedMetroFilter$;
  }

  getSelectedMetroFilterChildren(align?: 'horizontal' | 'vertical' | 'metro'): Observable<Filter[]> {
    return this.selectedMetroFilter$.pipe(
      map((mf) => {
        let metroChildren = structuredClone(mf).filter.children;
        if (align) {
          metroChildren = metroChildren.filter((f) => f.align === align);
        }
        return metroChildren.sort(this.sortByOrderThenAlphabetically);
      })
    );
  }

  getSelectedMetroFilterChildrenWithalignAndOperator(align: string, operator: string): Observable<Filter[]> {
    return this.getSelectedMetroFilterChildren().pipe(
      map((filters) =>
        filters.filter((f) => {
          let match = false;
          if (align) {
            match = match || f.align === align;
          }
          if (operator) {
            match = match || f.operator === operator;
          }
          return match
        })
      ),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  getAllMetroFilters(): Observable<Filter[]> {
    return this.metroFilters$.pipe(
      map((mfs) => mfs.map((mf) => mf.filter)),
      map((fs) => fs.sort(this.sortByOrderThenAlphabetically)),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }
  //#endregion

  //#region HorizontalFilters
  private activeHorizontalFilter$ = new BehaviorSubject<Filter>(undefined);

  getActiveHorizontalFilter(): Observable<Filter> {
    return this.activeHorizontalFilter$.asObservable();
  }
  getAvailableHorizontalFilters(): Observable<Filter[]> {
    return this.getSelectedMetroFilterChildren().pipe(map((mfs) => mfs.filter((f) => f.align === 'horizontal')));
  }

  setHorizontalFilter(filter: Filter): void {
    if (filter.align === 'horizontal') {
      this.activeHorizontalFilter$.next(filter);
      this.resetActiveVerticalFilters();
    }
  }

  getActiveHorizontalFilterChildren(): Observable<Filter[]> {
    return this.activeHorizontalFilter$.pipe(
      switchMap((hf) => {
        if (hf) {
          const horizontalChildren = structuredClone(hf).children;
          return of(horizontalChildren.sort(this.sortByOrderThenAlphabetically));
        }
        return this.getSelectedMetroFilterChildren('vertical');
      })
    );
  }

  resetHorizontalFilters() {
    this.activeHorizontalFilter$.next(undefined);
  }
  //#endregion

  //#region VerticalFilters
  /**
   * Filtri verticali attivi nel formato
   * {
   *   parentFilter: Filter,
   *   values: Filter[]
   * }
   * dove parentFilter è il contenitore di una delle sezione dei filtri verticali
   */
  private activeVerticalFilters$ = new BehaviorSubject<ActiveFilter[]>([]);

  /**
   * @param filter
   * Controlla che il filtro sia disponibile come figlio del metro attivo
   * Se il filtro è già tra gli attivi viene rimosso altrimenti viene aggiunto
   */
  toggleActiveVerticalFilter(parentFilter: Filter, filter: Filter): void {
    const currentAvailableValues = parentFilter.children;

    const isAvailable = currentAvailableValues.find((af) => af.featureId === filter.featureId);
    if (isAvailable) {
      const currentActiveValues =
        this.activeVerticalFilters$.value.find((f) => f.parentFilter.featureId === parentFilter.featureId)?.values ?? [];
      const filterAlreadyActive = currentActiveValues.find((af) => af.featureId === filter.featureId);
      if (filterAlreadyActive) {
        this._removeActiveVerticalFilter(parentFilter, filter);
      } else {
        this._addActiveVerticalFilter(parentFilter, filter);
      }
      return;
    }
    console.warn('toggle unavailable filter');
  }

  //tutto da ottimizzare
  private _addActiveVerticalFilter(parentFilter: Filter, filterToAdd?: Filter): void {
    let activeFilter: ActiveFilter = this.activeVerticalFilters$.value.find((af) => af.parentFilter.featureId == parentFilter.featureId);
    if (!activeFilter) {
      activeFilter = { parentFilter, values: [] };
    }
    if (filterToAdd) {
      activeFilter.values.push(filterToAdd);
    }

    const newActiveFilters = this.activeVerticalFilters$.value.filter((af) => {
      return af.parentFilter.featureId !== parentFilter.featureId;
    });

    this.activeVerticalFilters$.next([...newActiveFilters, activeFilter]);
  }

  //tutto da ottimizzare
  private _removeActiveVerticalFilter(parentFilter: Filter, filterToRemove?: Filter): void {
    const currentValues = this.activeVerticalFilters$.value.find((af) => af.parentFilter.featureId == parentFilter.featureId).values;
    if (currentValues.length === 1 && currentValues[0].featureId === filterToRemove?.featureId) {
      //remove from array
      const newActiveFilters = this.activeVerticalFilters$.value.filter((af) => af.parentFilter.featureId !== parentFilter.featureId);
      this.activeVerticalFilters$.next([...newActiveFilters]);
    } else {
      //remove value
      const newActiveFilters = [...this.activeVerticalFilters$.value];
      const filterValues = newActiveFilters.find((af) => af.parentFilter.featureId === parentFilter.featureId);
      filterValues.values = filterValues.values.filter((fv) => fv.featureId !== filterToRemove?.featureId);
      this.activeVerticalFilters$.next([...newActiveFilters]);
    }
  }

  public addActiveVerticalFilter(parentFilter, filter: Filter): void {
    const currentAvailableValues = this.selectedMetroFilter$.value?.filter.children;
    const isAvailable = currentAvailableValues.find((af) => af.name === filter.name);
    if (isAvailable) {
      this._addActiveVerticalFilter(parentFilter, filter);
    } else {
      console.warn('add unavailable filter');
    }
  }

  public removeActiveVerticalFilter(parentFilter: Filter, filter: Filter): void {
    const currentAvailableValues = this.selectedMetroFilter$.value?.filter.children;
    const isAvailable = currentAvailableValues.find((af) => af.name === filter.name);
    if (isAvailable) {
      this._removeActiveVerticalFilter(parentFilter, filter);
    } else {
      console.warn('remove unavailable filter');
    }
  }

  getActiveVerticalFilters(): Observable<ActiveFilter[]> {
    return this.activeVerticalFilters$.asObservable();
  }
  // getActiveVerticalFiltersValuesByParentFeatureId(featureUUID): Observable<Filter[]> {
  //   return this.getActiveVerticalFilters().pipe(map(activeFilter => activeFilter.find(af => af.parentFilter.featureId === featureUUID).values));
  // }

  public resetActiveVerticalFilters(): void {
    this.activeVerticalFilters$.next([]);
  }
  //#endregion

  private sortByOrderThenAlphabetically(filterA: Filter, filterB: Filter) {
    if (filterA.order !== filterB.order) {
      return filterA.order - filterB.order; // Sort by order field
    } else {
      return filterA.label.localeCompare(filterB.label); // Sort by label field if order is the same
    }
  }

  initializeDefaultValues() {
    // let metroChildrenFilter = this.getSelectedMetroStepFilter(this.metroStepFilter.size - 1).children;
    // if(metroChildrenFilter.length === 1 && metroChildrenFilter[0].operator === 'and') {
    //  metroChildrenFilter = metroChildrenFilter[0].children
    // }
    // this.doInitializeDefaultValues(
    //   metroChildrenFilter
    // );
  }

  getFlattenedFilters() {
    return this.rootFilter$.pipe(
      map((rf) => {
        return this.flattenFilters([rf]);
      })
    );
  }

  private flattenFilters(filters: Filter[], parentId?: string): Array<Filter & { parentId?: string }> {
    return filters.reduce((acc, filter) => {
      const flattenedFilter = { ...filter, parentId };
      const children = flattenedFilter.children.length > 0 ? this.flattenFilters(flattenedFilter.children, flattenedFilter.featureId) : [];
      return [...acc, flattenedFilter, ...children];
    }, [] as Array<Filter & { parentId?: string }>);
  }
}
