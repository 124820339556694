import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

@NgModule({
  declarations: [],
  imports: [TranslateModule.forChild()],
  exports: [],
  providers: [TranslatePipe],
})
export class CataleanVersionControllerModule {
  public static forRoot(catalean): ModuleWithProviders<any> {
    return {
      ngModule: CataleanVersionControllerModule,
      providers: [{ provide: 'catalean', useValue: catalean }],
    };
  }
}
