<ng-container *ngIf="(this.alreadyLogged || (submitted && !dataLoaded)) && !loading && !cacheError;">
  <div class="loading-container" *ngIf="activateProgressBar">
    <div class="progress-bar-container">
      <div class="progress-bar" [style]="'width:'+this.progressBarPercentage+'%'"></div>
    </div>
    <div class="detail-container">
      {{loaderDetailText}}
    </div>
  </div>
</ng-container>
<form [hidden]="(alreadyLogged || (submitted && !dataLoaded)) && !loading && !cacheError" [formGroup]="loginForm"
      (ngSubmit)="onSubmit()">
  <div class="upperlogo-container">
    <ng-content select="img"></ng-content>
  </div>
  <div *ngIf="cacheError" class="invalid-feedback auth">
    <div>{{cacheError | translate}}</div>
  </div>
  <div class="button-container">
    <button *ngIf="!loading" type="submit" [disabled]="loading" class="btn btn-primary">
      {{"LOGIN_FORM.LOGIN" | translate}}
    </button>
    <button *ngIf="!loading && registration" type="button" [disabled]="loading" class="btn btn-secondary"
            (click)="onRegistrationClick()">
      {{"LOGIN_FORM.SIGNUP" | translate}}
    </button>
    <ng-content *ngIf="loading" select="[type=loading-indicator]"></ng-content>
  </div>
</form>
