import { CataleanModelsModule } from 'catalean-models';
import { CataleanProviderModule } from 'catalean-provider';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './login-form/login-form.component';
import { RecoveryFormComponent } from './recovery-form/recovery-form.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [LoginFormComponent, RecoveryFormComponent],
  imports: [ReactiveFormsModule, FormsModule, CommonModule, CataleanProviderModule, CataleanModelsModule, TranslateModule, IonicModule],
  exports: [LoginFormComponent, RecoveryFormComponent],
})
export class CataleanAuthenticationModule {}
