export interface OperationPermission {
    entityUUID: string,
    entityType: OperationPermissionEntityType,
    subjectUUID: string,
    subjectType: OperationPermissionSubjectType,
    operationList: OperationPermissionType[]
}

export const OperationPermissionEntityType = {
    ASSET: 'asset',
} as const;

export const OperationPermissionSubjectType = {
    APPLICATION_GROUP: 'applicationGroup',
    APPLICATION_SMART_GROUP: 'applicationSmartGroup',
    APPLICATION_USER: 'applicationUser',
} as const;

export const OperationPermissionType = {
    DOWNLOADS: 'downloads',
    SHARE: 'share',
    OPEN_IN_APP: 'openInApp',
    // ADDABLE_IN_COLLECTION: 'addableInCollection'     //non è da usare attualmente
} as const;

type ObjectValues<T> = T[keyof T];

export type OperationPermissionSubjectType = ObjectValues<typeof OperationPermissionSubjectType>;
export type OperationPermissionEntityType = ObjectValues<typeof OperationPermissionEntityType>;
export type OperationPermissionType = ObjectValues<typeof OperationPermissionType>;