import { Injectable } from '@angular/core';
import { OperationPermission } from 'catalean-models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OperationPermissionManagerService {
  constructor() {}

  private _operationPermissions: OperationPermission[] = [];

  mapAndSaveOperationPermission(operationPermission: OperationPermission[]) {
    if(Array.isArray(operationPermission)) {
      this._operationPermissions = [...operationPermission];
    }
  }

  getOperationPermissionFromAssetUUID(id: string): OperationPermission[] | undefined {
    return this._operationPermissions.filter((opePermission) => opePermission.entityUUID === id);
  }

  getObservableOperationPermissionFromAssetUUID(id: string): Observable<OperationPermission[] | undefined> {
    return of(this._operationPermissions.filter((opePermission) => opePermission.entityUUID === id));
  }

  getAllOperationPermission(): OperationPermission[] {
    return this._operationPermissions
  }
}
