import { Inject, Injectable } from '@angular/core';
import { ResourceManagerService } from 'catalean-provider';
import { LocalResource, Resource } from 'catalean-models';
import { EMPTY, Observable, Subject, filter, from, iif, map, mergeMap, of, switchMap, toArray } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { CataleanStorageService } from 'catalean-storage';
import { LocalResourcesService } from 'catalean-local-files';
import { CurrentPlatformService } from 'src/app/services/current-platform.service';
import { Directory, Filesystem, Encoding } from '@capacitor/filesystem';
import { CapacitorHttp } from '@capacitor/core';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class DeviceResourcesService {
  readonly isDeletingAllAssetsLSKey = 'isDeletingAllAssets';
  readonly assetFolder = 'assets';
  private resourcesToBeDownloaded: Resource[] = [];
  private resourcesToDownloadWithPriority: Resource[] = [];
  private failedDownloads: Resource[] = [];

  // file presenti nel db all'avvio dell'app o scaricati in seguito
  private resourceKeysToSaveLater: string[] = [];
  downloadedCounter = 0;
  private stopDownload = false;
  isDeletingAllAssets = false;

  fileDownloaded = new Subject<void>();

  constructor(
    @Inject('resourceBatchLimit') private resourceBatchLimit: number = 20,
    @Inject('checkOnFileSystem') private checkOnFileSystem = false,
    private resourceManager: ResourceManagerService,
    private sanitizer: DomSanitizer,
    private localResources: LocalResourcesService,
    private platform: CurrentPlatformService,
    private storage: CataleanStorageService
  ) {
    this.isDeletingAllAssets = localStorage.getItem(this.isDeletingAllAssetsLSKey) === '1';
    if (this.isDeletingAllAssets) {
      this.platform.ready().then(() => this.deleteAllAssets());
    }
  }

  set StopDownload(stopDownload) {
    this.stopDownload = stopDownload;
  }

  get StopDownload() {
    return this.stopDownload;
  }

  private static isNotEncodedURL(resourceUrl: string) {
    if (resourceUrl) {
      return decodeURIComponent(resourceUrl) === resourceUrl;
    }
    return false;
  }

  // it works on the first level only so it is only compatible with datalean media library
  private async deleteUnnecessaryAssets() {
    // const directoryEntry = await this.file.resolveDirectoryUrl(this.DestinationFolderPath);
    // const directory = await this.file.getDirectory(directoryEntry, this.DestinationFolderPath, {});
    // directory.createReader().readEntries((fileList: Entry[]) => {
    //   fileList.forEach((file: Entry) => {
    //     if (this.resourceManager.Resources.find((res: Resource) => res.url.includes(file.name))) {
    //       file.remove(() => {});
    //     }
    //   });
    // });
  }

  public downloadRemoteResources(deleteUnnecessaryAssets?: boolean): Observable<number> {
    const createAssetsDir$ = from(
      Filesystem.mkdir({
        path: 'assets',
        directory: Directory.Data,
        recursive: false,
      }).catch(() => {})
    );

    const processResources$ = (resourcesToProcess: number) =>
      from(this.resourceManager.Resources).pipe(
        mergeMap((resource) =>
          from(this.resourceNeedsToBeDownloaded({ ...resource })).pipe(
            map((isResourceToDownload) => {
              if (isResourceToDownload) {
                this.resourcesToBeDownloaded.push({ ...resource });
              }
              return --resourcesToProcess <= 0;
            })
          )
        ),
        filter((shouldDownloadResources) => shouldDownloadResources),
        switchMap(() => from(this.doDownloadRemoteResources())),
        switchMap((counter) => {
          if (this.resourceKeysToSaveLater.length > 0) {
            return from(this.resourceKeysToSaveLater).pipe(
              mergeMap((resourceKey) =>
                iif(
                  () => !!this.localResources.getLocalResourceByUrl(resourceKey),
                  from(this.localResources.addOrUpdateStorageResource(resourceKey, this.localResources.getLocalResourceByUrl(resourceKey))),
                  EMPTY
                )
              ),
              toArray(),
              map(() => counter)
            );
          } else {
            return of(counter);
          }
        })
      );

    if (this.resourceManager.Resources?.length > 0) {
      let resourcesToProcess = this.resourceManager.Resources.length;
      if (deleteUnnecessaryAssets) {
        this.deleteUnnecessaryAssets();
      }
      return createAssetsDir$.pipe(
        switchMap(() => from(this.localResources.init())),
        switchMap(() => processResources$(resourcesToProcess))
      );
    } else {
      return of(this.downloadedCounter);
    }
  }

  public async resourceNeedsToBeDownloaded(resource: Resource): Promise<boolean> {
    const resourceHasBeenAlreadyEvaluatedForDownload = this.resourceHasBeenAlreadyEvaluatedForDownload(resource);
    const hasRemoteUrl = this.hasRemoteUrl(resource);
    if (resourceHasBeenAlreadyEvaluatedForDownload || !hasRemoteUrl) {
      return false;
    }
    const hasBeenDownloaded = await this.resourceHasBeenDownloaded(resource, false);
    return !hasBeenDownloaded;
  }

  private resourceHasBeenAlreadyEvaluatedForDownload(resource: Resource): boolean {
    return (
      this.resourcesToBeDownloaded.some((resourceToBeDownloaded) => resourceToBeDownloaded.url === resource.url) ||
      this.resourcesToDownloadWithPriority.some((resourceToBeDownloaded) => resourceToBeDownloaded.url === resource.url)
    );
  }

  private fixNameForFileSystem(name) {
    if (name.includes('%20')) name = name.replace(/\%20/g, '_');
    if (name.includes(' ')) name = name.replace(/ /g, '_');
    if (name.includes('%E2%80%93')) name = name.replace(/\%E2\%80\%93/g, '-');
    if (name.includes('%2B')) name = name.replace(/\%2B/g, '+');
    if (name.includes('%2C')) name = name.replace(/\%2C/g, '_');
    if (name.includes('%26')) name = name.replace(/\%2C/g, '&');
    if (name.includes('%')) name = name.replace(/\%/g, '');
    return name;
  }

  // manca un pezzo di flusso
  // se è presente nel db viene controllato altrimenti no
  // quindi il caso di db corrotto(logout?) non è gestito
  private async resourceHasBeenDownloaded(resource?: Resource, searchInStorageAlternatively?: boolean): Promise<boolean> {
    if (resource) {
      let storageResource = this.localResources.getLocalResourceByUrl(resource.url);
      if (!storageResource && searchInStorageAlternatively) {
        storageResource = await this.localResources.getLocalResourceByUrlFromStorage(resource.url);
      }
      if (storageResource) {
        if (this.checkOnFileSystem) {
          let fileName = storageResource.url.split('/').pop();
          fileName = this.fixNameForFileSystem(fileName);
          try {
            return !!(await Filesystem.stat({
              directory: Directory.Data,
              path: `${this.assetFolder}/${fileName}`,
            }));
          } catch (error) {
            // console.error('Error with file check: ', JSON.stringify(error));
            return false;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }

  public async doDownloadRemoteResources(): Promise<number> {
    this.stopDownload = false;
    if (navigator.onLine && (this.resourcesToBeDownloaded.length || this.resourcesToDownloadWithPriority.length)) {
      try {
        await this.downloadResourceRecursive();
      } catch (error) {}
    }
    this.stopDownload = true;
    return this.downloadedCounter;
  }

  private async downloadResourceRecursive(): Promise<number> {
    if ((this.resourcesToBeDownloaded.length || this.resourcesToDownloadWithPriority.length) && !this.StopDownload) {
      // const priority = this.resourcesToDownloadWithPriority.length > 0;
      const resourceToDownload = this.resourcesToDownloadWithPriority.length
        ? this.resourcesToDownloadWithPriority.pop()!
        : this.resourcesToBeDownloaded.pop()!;

      const resourceDownloaded = await this.downloadResourceAndAddLocalResource(resourceToDownload);
      this.resourceKeysToSaveLater.push(resourceToDownload.url);

      if (resourceDownloaded) {
        this.downloadedCounter++;
        /* if (priority) {
                  console.log('resource %s downloaded with priority', resourceToDownload.url);
                } */
        // DO updateDownloadProgressBarValue  => implement when needed
      } else {
        // DO updateDownloadProgressBarErrorValue => implement when needed
      }

      if (this.resourceKeysToSaveLater.length === this.resourceBatchLimit) {
        // console.log('%s addOrUpdateLocalResource', this.resourceKeysToSaveLater.length);
        for (const resourceKey of this.resourceKeysToSaveLater) {
          const localResource = this.localResources.getLocalResourceByUrl(resourceKey);
          if (localResource) {
            //TODO Promise.all
            await this.localResources.addOrUpdateStorageResource(resourceKey, localResource);
          }
        }
        this.resourceKeysToSaveLater = [];
      }
      try {
        await this.downloadResourceRecursive();
      } catch (error) {}
    }
    return this.downloadedCounter;
  }

  private cleanFormat(format: string): string {
    if (format) {
      switch (format) {
        case 'svg+xml':
          format = 'svg';
          break;
        default:
          break;
      }
      return format;
    }
    return '';
  }

  private async downloadResourceAndAddLocalResource(resourceToDownload: Resource): Promise<boolean> {
    let fileNameByUrl = resourceToDownload.url.split('/').pop()!.trim();
    fileNameByUrl = this.fixNameForFileSystem(fileNameByUrl);

    if (resourceToDownload.format) {
      fileNameByUrl += '.' + this.cleanFormat(resourceToDownload.format);
    }
    const resourceUrl = resourceToDownload.url;

    try {
      await this.downloadFile(resourceUrl, fileNameByUrl);
      // this.updateExecutedDownloadArray(resourceToDownload);  evaluate if needed
      //console.log('downloaded file -> then save partial file path to storageResources %s', fileNameByUrl);
      this.localResources.setLocalResourceByUrl(resourceToDownload.url, {
        ...resourceToDownload,
        url: fileNameByUrl,
      });
      this.fileDownloaded.next();
      // this.addOrUpdateLocalResource(resourceToDownload, destinationPath, localResourceEntryURL);
      // console.log('Successful downloaded resource %s', resourceUrl);
      return true;
    } catch (error) {
      // this.updateExecutedDownloadArray(resourceToDownload);  evaluate if needed
      this.updateFailedDownloadArray(resourceToDownload);
      //console.error('Failure(%s) downloading resource %s', error.http_status ? error.http_status : error, resourceUrl);
      return false;
    }
  }

  private async downloadFile(resourceUrl: string, fileName: string): Promise<string> {
    let encodedUrl = resourceUrl.replace(/\>/g, '%3E');
    try {
      if (DeviceResourcesService.isNotEncodedURL(resourceUrl)) {
        // IS NOT ENCODED => THEN ENCODE URI
        encodedUrl = encodeURI(resourceUrl);
      }
    } catch (error) {
      encodedUrl = encodeURI(resourceUrl);
    }

    if (this.platform.is('electron')) {
      const downloadResult = await Filesystem.downloadFile({
        url: resourceUrl,
        path: `${this.assetFolder}/${fileName}`,
        directory: Directory.Data,
      });

      return downloadResult.path ;
    } else {
      const response = await CapacitorHttp.get({
        url: resourceUrl,
      });

      if (response.status) {
        const writeFileResult = await Filesystem.writeFile({
          path: `${this.assetFolder}/${fileName}`,
          data: response.data,
          directory: Directory.Data,
          encoding: Encoding.UTF8,
        });

        return writeFileResult.uri;
      }
    }
    throw new Error('failed to download ' + fileName);
  }

  private updateFailedDownloadArray(resourceToDownload: Resource) {
    if (this.failedDownloads?.filter((notDownloadedResource) => notDownloadedResource.url === resourceToDownload.url)?.length === 0) {
      // resourceToDownload not found in failedDownloads array
      this.failedDownloads.push(resourceToDownload);
    }
  }

  public get Resources(): Resource[] {
    return this.resourceManager.Resources;
  }

  public findRemoteResource(remoteResourceURL) {
    return this.resourceManager.Resources.find((resource) => resource.url === remoteResourceURL);
  }

  /**
   * Returns the URL of a resource; if the resource has been already downloaded it returns the local url, otherwise the remote url, always from storageResources(photo);
   * if the resource has not been downloaded, it downloads the resource;
   */
  public async getResourceUrl(
    remoteResourceURL: string | { url: string; previewURL?: string },
    startDownloadIfNeeded: boolean,
    sanitized?: boolean,
    convertIonic?: boolean
  ): Promise<string | SafeResourceUrl> {
    if (typeof sanitized === 'undefined') {
      sanitized = true;
    }
    if (typeof remoteResourceURL !== 'string') {
        remoteResourceURL = remoteResourceURL?.url;
    } else {
      remoteResourceURL = remoteResourceURL.trim();
    }
    if (remoteResourceURL) {
      const localResource = this.localResources.getLocalResourceByUrl(remoteResourceURL);

      if (localResource) {
        // console.log('getInstantResourceUrl FOUND >%s< in Photo', this.storageResources[remoteResourceURL].url);
        const localUrl = localResource.url;
        const fullPath = (
          await Filesystem.getUri({
            directory: Directory.Data,
            path: `${this.assetFolder}/${localUrl}`,
          })
        ).uri;
        if (sanitized) {
          return this.sanitizer.bypassSecurityTrustResourceUrl(window.Ionic.WebView.convertFileSrc(fullPath));
        }
        return !convertIonic ? fullPath : window.Ionic.WebView.convertFileSrc(fullPath);
      }
      /* <- force remoteResource download if needed */
      if (startDownloadIfNeeded) {
        const remoteResource = this.findRemoteResource(remoteResourceURL);
        /* force remoteResource download if needed -> */
        this.resourceIsNotYetStoredLocally(remoteResourceURL, remoteResource).then(
          (isNotYetStoredLocally) => {
            if (isNotYetStoredLocally) {
              const resourceIndex = this.resourcesToBeDownloaded.findIndex(
                (resourceToBeDownloaded) => resourceToBeDownloaded.url === remoteResource?.url
              );
              if (resourceIndex > -1) {
                this.resourcesToDownloadWithPriority.push(this.resourcesToBeDownloaded.splice(resourceIndex, 1)[0]);
              } else {
                //console.log('INFO: ', this.resourcesToBeDownloaded, remoteResource);
              }
            } else {
              //console.log('INFO : remoteResource >%s< is %s', remoteResource.url, isNotYetStoredLocally ? 'not stored yet' : 'stored');
            }
          },
          (error) => {
            console.error('INFO: Error detecting if %s is not yet stored locally : %s', remoteResourceURL, error);
          }
        );
      }
    }
    return remoteResourceURL;
  }

  public async getResource(
    remoteResourceURL,
    startDownloadIfNeeded: boolean,
    sanitized?: boolean,
    convertIonic?: boolean
  ): Promise<LocalResource | Resource> {
    if (typeof remoteResourceURL !== 'string') {
      remoteResourceURL = remoteResourceURL?.url;
    } else {
      remoteResourceURL = remoteResourceURL.trim();
    }

    if (remoteResourceURL?.trim()?.length > 0) {
      remoteResourceURL = remoteResourceURL.trim();
      const localResource = this.localResources.getLocalResourceByUrl(remoteResourceURL);

      if (localResource) {
        // console.log('getInstantResourceUrl FOUND >%s< in Photo', this.storageResources[remoteResourceURL].url);
        let localUrl: string | SafeUrl = localResource.url;
        const fullPath = (
          await Filesystem.getUri({
            directory: Directory.Data,
            path: `${this.assetFolder}/${localUrl}`,
          })
        ).uri;
        if (sanitized) {
          localUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.Ionic.WebView.convertFileSrc(fullPath));
        } else {
          if (convertIonic) {
            localUrl = window.Ionic.WebView.convertFileSrc(fullPath);
          } else {
            localUrl = fullPath;
          }
        }
        return {
          ...localResource,
          url: localUrl,
        };
      }
      /* <- force remoteResource download if needed */
      if (startDownloadIfNeeded) {
        const remoteResource = this.findRemoteResource(remoteResourceURL);
        /* force remoteResource download if needed -> */
        this.resourceIsNotYetStoredLocally(remoteResourceURL, remoteResource).then(
          (isNotYetStoredLocally) => {
            if (isNotYetStoredLocally) {
              const resourceIndex = this.resourcesToBeDownloaded.findIndex(
                (resourceToBeDownloaded) => resourceToBeDownloaded.url === remoteResource?.url
              );
              if (resourceIndex > -1) {
                this.resourcesToDownloadWithPriority.push(this.resourcesToBeDownloaded.splice(resourceIndex, 1)[0]);
              } else {
                //console.log('INFO: ', this.resourcesToBeDownloaded, remoteResource);
              }
            } else {
              //console.log('INFO : remoteResource >%s< is %s', remoteResource.url, isNotYetStoredLocally ? 'not stored yet' : 'stored');
            }
          },
          (error) => {
            console.error('INFO: Error detecting if %s is not yet stored locally : %s', remoteResourceURL, error);
          }
        );
      }
    }
    return this.findRemoteResource(remoteResourceURL);
  }

  private hasRemoteUrl(resource?: Resource): boolean {
    if (resource?.url) {
      return resource.url.trim().substring(0, 4) === 'http';
    }
    return false;
  }

  private async resourceIsNotYetStoredLocally(remoteResourceURL: string, resource?: Resource): Promise<boolean> {
    const hasRemoteUrl = this.hasRemoteUrl(resource);

    if (!hasRemoteUrl) {
      return true;
    }

    const hasBeenDownloaded = await this.resourceHasBeenDownloaded(resource, true);
    const isNotYetStoredLocally = !hasRemoteUrl || !resource || !hasBeenDownloaded;

    return isNotYetStoredLocally;
  }

  async deleteAllAssets(): Promise<void> {
    // TODO: delete version files too
    // const lastStopDownloadStatus = this.StopDownload;
    // this.StopDownload = true;
    // this.isDeletingAllAssets = true;
    // localStorage.setItem(this.isDeletingAllAssetsLSKey, '1');
    // try {
    //   await this.file.removeRecursively(this.file.dataDirectory, 'assets');
    //   await this.storage.removeAllAssetResources();
    //   this.failedDownloads = [];
    //   this.resourcesToDownloadWithPriority = [];
    //   this.resourcesToBeDownloaded = [];
    //   this.StopDownload = lastStopDownloadStatus;
    //   localStorage.setItem(this.isDeletingAllAssetsLSKey, '0');
    //   this.isDeletingAllAssets = false;
    // } catch (error) {
    //   console.error(error);
    // }
  }

  get ResourcesToBeDownloadedCount() {
    return this.resourcesToBeDownloaded.length + this.resourcesToDownloadWithPriority.length;
  }

  get FailedDownloadsCount() {
    return this.failedDownloads.length;
  }
}
