import { Pipe, PipeTransform, inject } from '@angular/core';
import { DeviceResourcesService } from 'catalean-local-files';
import { Resource } from 'catalean-models';

@Pipe({
  name: 'toLocalResource',
  standalone: true,
})
export class ToLocalResourcePipe implements PipeTransform {

  localFileManager = inject(DeviceResourcesService)

  transform(url: string  | {url: string, previewURL?: string}, startDownloadIfNeeded = true, sanitized?: boolean, convertIonic?: boolean): Promise<Resource> {
    return this.localFileManager.getResource(url,startDownloadIfNeeded,sanitized,convertIonic) as Promise<Resource>;
  }

}
