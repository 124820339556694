import { Pipe, PipeTransform } from '@angular/core';
import { CataleanLocalizationService } from 'catalean-localization';
import { LocalizableField } from 'catalean-models';


@Pipe({
  name: 'toLocalizedValuePipe',
})
export class ToLocalizedValuePipe implements PipeTransform {
  constructor(private localizationService: CataleanLocalizationService) {}


  transform(value: string | {value:string, language:string}[] | LocalizableField | number | Record<string, string>, forcedLocale?: string) {
    if (value === undefined || value === null) {
      return '';
    }
    const defaultLan = this.localizationService.DefaultLanguage;
    const activeLan = forcedLocale || this.localizationService.ActiveLanguage || defaultLan;
    if (value instanceof LocalizableField) {
      return value.getLocalizedValue(activeLan, defaultLan);
    } else if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      return value;
    } else if (Array.isArray(value)) {
      return value.find((v) => v.language === (activeLan || defaultLan))?.value || '';
    } else {
      return value?.value ?? value?.[activeLan] ?? value?.[defaultLan] ?? '';
    }
  }
}
