
export interface CoreEntity {
  name: string;
  related?: string[]; // lo tengo per retrocompatibilità
  uuid: string;
  features:  string[] ;
  structureUUID: string;
  status: string;
  order: number;
  relatedProducts?: string[];
  //[structureFieldName: string]: any;
}
