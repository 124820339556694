import { Component, OnInit,Output, EventEmitter,} from '@angular/core';
import {CataleanLocalizationService} from "./catalean-localization.service";
import {MenuController, NavController} from "@ionic/angular";

@Component({
  selector: 'cat-localization-selector',
  templateUrl: './catalean-localization.component.html',
})
export class CataleanLocalizationComponent implements OnInit {

  languages = [];
  @Output() clickNewLanguage = new EventEmitter<boolean>();

  constructor(private navCtrl: NavController,
              public cataleanLocalizationService: CataleanLocalizationService,
              public menuController: MenuController) {
  }

  ngOnInit():void {
    this.languages = this.cataleanLocalizationService.AvailableLocales
  }

  changeLanguage(language) {
    this.cataleanLocalizationService.setActiveLanguage(language).subscribe((successChangeLanguage) => {
      this.menuController.isOpen('catalean-menu').then((opened) => {
            if (opened) {
              this.menuController.close()
                  .then((result) => {
                        this.forceReloadingAfterChangeLanguage();
                      },
                      (error) => {
                        console.log('Error on closing menu: %s', error);
                        this.forceReloadingAfterChangeLanguage();
                      });
            } else {
              this.forceReloadingAfterChangeLanguage();
            }
          },
          (error) => {
            console.log('Error checking if menu is opened: %s', error);
            this.forceReloadingAfterChangeLanguage();
          });
    });
  }

  forceReloadingAfterChangeLanguage() {
    this.navCtrl.navigateRoot('').then((success) => {
      this.clickNewLanguage.emit(success);
    });
  }
}
