import { CommonModule } from '@angular/common';
import { CataleanProviderModule } from 'catalean-provider';
import { CataleanModelsModule } from 'catalean-models';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DownloadProgressComponent } from './components/download-progress/download-progress.component';
import {TranslateModule} from '@ngx-translate/core';
@NgModule({
  declarations: [DownloadProgressComponent],
  imports: [
    CommonModule,
    CataleanModelsModule,
    CataleanProviderModule,
    TranslateModule
  ],
  exports: [DownloadProgressComponent]
})
export class CataleanLocalFilesModule {
  public static forRoot(resourceBatchLimit, checkOnFileSystem = true): ModuleWithProviders<any> {
    return {
      ngModule: CataleanLocalFilesModule,
      providers: [
        {
          provide: 'resourceBatchLimit',
          useValue: resourceBatchLimit
        },
        {
          provide: 'checkOnFileSystem',
          useValue: checkOnFileSystem
        },
      ]
    };
  }
}
