import { Injectable } from '@angular/core';
import { Content } from 'catalean-models';
import { Observable } from 'rxjs';
import { EntityManagerService } from './entity-manager.service';
import { FilterManagerService } from './filter-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ContentManagerService extends EntityManagerService<Content> {
  constructor(filterService: FilterManagerService) {
    super(filterService);
  }
  tInitFilter;
  tEndFilter;

  getContentsByStructureUUID(structureUUID: string): Content[] {
    return this._entities.filter((c) => c.structureUUID === structureUUID);
    // return this.contents$.pipe(map(contents => contents.filter(c => c.structureUUID === structureUUID)));
  }

  getFilteredContents(): Observable<Content[]> {
    return this.getFilteredEntities();
  }

  get Contents(): Content[] {
    return this._entities;
  }

  getContent(uuid): Content {
    return this.getEntityById(uuid);
  }

  resetContents() {
    this.resetEntities();
  }

  mapAndSaveContent(contents: Content[]) {
    super.mapAndSaveEntites(contents);
  }
}
