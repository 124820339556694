import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataleanDataProviderService } from 'catalean-provider';
import { User } from 'catalean-models';
import { TranslatePipe } from '@ngx-translate/core';



@Component({
    selector: 'cat-recovery-form',
    templateUrl: './recovery-form.component.html',
    styleUrls: ['./recovery-form.component.scss'],
    providers: [TranslatePipe]
})

export class RecoveryFormComponent {

    @Output() tapLogin = new EventEmitter<void>();
    requestSent = false;
    recoveryForm: UntypedFormGroup;
    isValid = true;
    requestJustSent = false;
    userNotPresent = false;
    @ViewChild('inputUserName') inputUserName;


  constructor(private router: Router,
              private formBuilder: UntypedFormBuilder,
              private dataServiceProvider: DataleanDataProviderService) {}

  ngOnInit(){
    this.recoveryForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
    this.recoveryForm.reset();
  }

  get form() { return this.recoveryForm.controls; }

  sendRequest(){
    if (this.recoveryForm.invalid) {
      this.isValid = false;
    } else {
      this.isValid = true;
      const username = this.inputUserName.nativeElement.value;
      this.dataServiceProvider.getUserByFieldAndValue('username', username)
          .subscribe(
              (data: User) => {
                this.dataServiceProvider.postUserUUIDForPasswordRecovery(data.Id)
                    .subscribe(
                        (succes) => {
                            this.requestSent = true;
                        },
                        (error) => {
                            this.requestSent = false;
                            this.requestJustSent = true;
                        }
                    );
              },
              (error) => {
                  this.requestSent = false;
                  this.userNotPresent = true;
              }
          );
    }
  }

}
