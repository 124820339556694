import { ModuleWithProviders, NgModule } from '@angular/core';
import { CataleanProviderModule } from 'catalean-provider';

@NgModule({
  declarations: [],
  imports: [CataleanProviderModule],
  exports: []
})
export class CataleanConfigurationModule {
  public static forRoot(environment): ModuleWithProviders<any> {
    return {
      ngModule: CataleanConfigurationModule,
      providers: [{ provide: 'env', useValue: environment}],
    };
  }
}
