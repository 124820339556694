import {DataleanDataProviderService} from 'catalean-provider';
import {User, AuthenticationToken} from 'catalean-models';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserSettingsService} from './user-settings-service.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {

  constructor(private userSettingService: UserSettingsService, private dataProviderService: DataleanDataProviderService) {
  }

  getAuthenticationTokenInstant(): AuthenticationToken | undefined {
    return this.userSettingService.getStoredToken();
  }


  getAuthenticatedUserInstant(): User | undefined {
    return this.userSettingService.getAuthenticatedUserData();
  }

  setAuthenticatedUser(user: User) {
    this.userSettingService.saveAuthenticatedUserData(user);
  }
}
