import { Component, OnInit, Output, EventEmitter, Input, ViewChild, AfterViewInit, OnDestroy, TemplateRef } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { Filter, MetroFilter } from 'catalean-models';
import { SwiperComponent } from 'swiper/angular';
import { CataleanConfigService } from 'catalean-configuration';
import { map } from 'rxjs/operators';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'cat-hamburger-menu',
  templateUrl: './catalean-hamburger-menu.component.html',
  styleUrls: ['./catalean-hamburger-menu.component.scss'],
})
export class CataleanHamburgerMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() filterSelected = new EventEmitter<any>();
  @Output() clickLogout = new EventEmitter<boolean>();
  @Output() clickChangelog = new EventEmitter<any>();
  @Input() onMenuOpen: Subject<void>;
  @Input() showChangelog = false;
  @Input() filters: Filter[] = [];
  @Input() set rootFilter(rf: Filter) {
    if(rf) {
      this._rootFilter = rf
      this.filterMapList = [ {
        [rf.featureId]: {
          parent: rf,
          children: rf.children
        }
        }
      ]
      this.recursiveAddLevels(rf.children)
      this.visibleFilterUUID = [rf.featureId]
    }
  };
  @Input() public buttonTemplate: TemplateRef<any>;
  //parametro che indica s navigare in profondità o meno nell'albero dei filtri
  @Input() disableDeepNavigationFilter: boolean = false

  visibleFilterUUID: string[];

  private recursiveAddLevels(filters:Filter[],level=1){
    for (const f of filters) {
      if(f.children && f.align === 'metro') {
        if(!this.filterMapList[level]) {
          this.filterMapList[level] = {}
        }
        this.filterMapList[level][f.featureId] = {parent: f, children:f.children}
        this.recursiveAddLevels(f.children,level+1)
      }
    }
  }

  @Input() activeFilter: MetroFilter;
  @Input() userDeletion?: { address: string, subject: string, body: string };
  @ViewChild('filterSlides') filterSlide: SwiperComponent

  goBack() {
    if(this.visibleFilterUUID.length > 1) {
      this.visibleFilterUUID.pop()
      this.filterSlide.swiperRef.slidePrev()
    } else {
      this.openMetroItem(this._rootFilter)
    }
  }

  filterMapList: { [featureId: string]: {parent:Filter, children:Filter[]} }[];
  _rootFilter: Filter;

  privacyText$ = this.configService.getConfigByName('privacy').pipe(map((p)=> p as string))
  //TODO: tipizzazione
  userDeletion$ = this.configService.getConfigByName('userDeletion').pipe(map((ud)=> ud as any))
  //TODO: tipizzazione
  additionalMenuItems$ = this.configService.getConfigByName('additionalMenuItems').pipe(map(ad=> ad as any[]))
  constructor(
    public modalCtrl: ModalController,
    public configService: CataleanConfigService,
    private menu: MenuController) {
  }

  onMenuOpenSubs?: Subscription;
  ngOnDestroy(): void {
    this.onMenuOpenSubs?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.filterSlide.swiperRef.allowTouchMove = false;
  }


  ngOnInit() {
    this.onMenuOpenSubs = this.onMenuOpen.subscribe(()=>{
      if(this.visibleFilterUUID?.length>1){
        this.visibleFilterUUID =[this.visibleFilterUUID[0]];
        this.filterSlide.swiperRef.slideTo(0);
      }
    })
  }

  closeHamburgerMenu() {
    this.menu.close('catalean-menu');
  }


  openChangelogModal() {
    this.clickChangelog.emit();
  }


  openMetroItem(button: Filter) {
    if (button) {
      if(
        button.children.length &&
        !this.disableDeepNavigationFilter && 
        button.children.some(b => b.align === 'metro') && button.featureId !== this._rootFilter.featureId
      ) {
        this.visibleFilterUUID.push(button.featureId)
        this.filterSlide.swiperRef.slideNext()
      } else {
        this.filterSelected.emit(button);
      }
    }
  }

  logOutClick() {

    this.clickLogout.emit();
  }

  openExternalLink(button) {
    if (button.mail && button.mail != '') {
      const email = document.createElement('a');
      email.href = 'mailto:' + button.mail + ';';
      email.click();
    }
    if (button.url && button.url.trim().length > 0) {
      Browser.open({url: button.url})
    }
  }
}

