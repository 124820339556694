import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { CataleanLocalizationService } from 'catalean-localization';
import { environment } from '../environments/environment';
import { Observable, Subject, combineLatest, first } from 'rxjs';
import { DeviceResourcesService } from 'catalean-local-files';
import { CataleanAuthenticationService } from 'catalean-authentication';
import { FilterManagerService } from 'catalean-provider';
import { CataleanConfigService } from '../../projects/catalean-configuration/src/lib/catalean-config.service';
import { CurrentPlatformService } from './services/current-platform.service';
import { App } from '@capacitor/app';
import { CataleanStorageService } from 'catalean-storage';
import { Filter } from 'catalean-models';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { ContentConfig, EventAndNewsConfig, GrasselliIntegration } from './models/grasselli-integration';
import { CataleanCollectionService } from 'catalean-collection';

const env = environment;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public readonly VERSION_PREFIX: string = 'v';
  public version = '';
  public openMenuSubject = new Subject<void>();
  isMobile: boolean;

  rootFilterChildren$ = this.filterService.getRootFilterChildren();
  activeFilter$ = this.filterService.getSelectedMetroFilter();
  rootFilter$ = this.filterService.getRootFilter();
  activatedRoute: ActivatedRoute;

  communicationsConfig$ = this.configManager.getConfigByName('comunicazioni') as Observable<ContentConfig>;
  eventsConfig$ = this.configManager.getConfigByName('events') as Observable<EventAndNewsConfig>;

  constructor(
    public platform: CurrentPlatformService,
    private router: Router,
    private menuController: MenuController,
    private localizationService: CataleanLocalizationService,
    private navCtrl: NavController,
    private deviceResourcesService: DeviceResourcesService,
    private authService: CataleanAuthenticationService,
    private filterService: FilterManagerService,
    private storageService: CataleanStorageService,
    private configManager: CataleanConfigService<GrasselliIntegration>,
    private collectionService: CataleanCollectionService
  ) {
    this.initializeApp();
    const deviceWidth = window.innerWidth;
    if (/Android|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      if (deviceWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
    if (!environment.production) {
      this.isMobile = false;
    }
  }

  async initializeApp() {
    await this.platform.ready();
    let language = await this.storageService.getLanguage();
    if (!language?.length) language = env.defaultLocale;
    //occorre settare la lingua precedentemente settata altrimenti
    //quando torni dalla login l'app viene ricreata e hai perso l'informazione
    this.localizationService.setActiveLanguage(language);
    this.localizationService.AvailableLocales = env.availableLocales;
  }

  ngOnInit() {
    App.addListener('appUrlOpen', (value) => {
      const appId = 'it.addiction.catalean.grasselli';

      if (value.url.includes(appId + '://')) {
        console.log('redirecting from universal link: ', value.url);
        if (Capacitor.getPlatform() === 'android') {
          location.href = 'http://localhost/' + value.url.replace(appId + '://', '');
        }
        if (Capacitor.getPlatform() === 'ios') {
          location.href = 'capacitor://localhost/' + value.url.replace(appId + '://', '');
          Browser.close();
        }
      } else {
        this.router.navigate(['initializer']);
      }
    });

    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        App.getInfo()
          .then(({ version }) => {
            this.version = this.VERSION_PREFIX + version;
          })
          .catch((err) => {
            console.log(err);
            this.version = this.VERSION_PREFIX + environment.version;
          });

        this.router.navigate([location.pathname.includes('/catalean-share') ? location.pathname.split('/') : '']);
      });
    } else {
      this.version = this.VERSION_PREFIX + environment.version;
      this.router.navigate([location.pathname.includes('/catalean-share') ? location.pathname.split('/') : '']);
    }
  }

  goTo(filter: Filter) {
    combineLatest([this.communicationsConfig$, this.eventsConfig$])
      .pipe(first())
      .subscribe(([comunicationConfig, eventsConfig]) => {
        this.filterService.setSelectedMetroFilter(filter);
        switch (filter.featureId) {
          case comunicationConfig.featureUUID:
            this.navigate(['communications']);
            break;
          case eventsConfig.featureUUID:
            this.navigate(['events-news']);
            break;
          default:
            filter.isRoot ? this.navigate(['metro']) : this.navigate(['metro', filter.name]);
            break;
        }
        this.menuController.close();
      });
  }

  async openCollections() {
    this.navigate(['collections']);
    this.menuController.close();
  }

  async navigate(paths: string[]) {
    await this.navCtrl.navigateRoot(paths);
  }

  logout() {
    this.deviceResourcesService.StopDownload = true;
    if (environment.logoutDataRetentionMode === 3) {
      this.deviceResourcesService.deleteAllAssets();
    }
    this.collectionService.clearCollectionStore()
    this.authService.logout();
  }

  onMenuOpen() {
    this.openMenuSubject.next();
  }
}
