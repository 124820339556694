import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CataleanHamburgerMenuModule } from 'catalean-hamburger-menu';
import { CataleanLocalFilesModule } from 'catalean-local-files';
import { CataleanModelsModule } from 'catalean-models';
import { CataleanProviderModule } from 'catalean-provider';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';

import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { createAnimation } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { CataleanAuthenticationService } from 'catalean-authentication';
import { CataleanConfigurationModule } from 'catalean-configuration';
import { CataleanLocalizationModule } from 'catalean-localization';
import { CataleanStorageModule } from 'catalean-storage';
import { CataleanVersionControllerModule } from 'catalean-version-controller';
import { LottieModule } from 'ngx-lottie';
import { authConfig, authModuleConfig } from '../config/auth.config';

registerLocaleData(localeIT);
const env = environment;

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

const authServiceInit = (authService: CataleanAuthenticationService) => {
  // init operazioni preliminari
  return () => {
    if (location.pathname.includes('/catalean-share')) {
      return;
    }
    return authService.runInitialLoginSequence();
  };
};

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    IonicModule.forRoot({
      navAnimation: () => createAnimation(),
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    CataleanProviderModule,
    CataleanConfigurationModule,
    CataleanModelsModule,
    CataleanHamburgerMenuModule,
    CataleanLocalFilesModule.forRoot(env.resourceBatchLimit),
    CataleanVersionControllerModule.forRoot(environment.catalean),
    CataleanHamburgerMenuModule.forRoot(environment.version),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CataleanStorageModule,
    CataleanLocalizationModule,
    NoopAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    { provide: AuthConfig, useValue: authConfig },
    { provide: OAuthModuleConfig, useValue: authModuleConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, useFactory: authServiceInit, deps: [CataleanAuthenticationService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    // Due provider con lo stesso value perchè i moduli non hanno una nomenclatura univoca
    {provide: 'environment', useValue: environment},
    { provide: 'env', useValue: environment}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
