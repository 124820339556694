// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// dev

export const environment = {
    production: false,
    organizationPrefix: 'GRS',
    organizationUUID: '8cdbe90a-4d5c-4e04-9975-bd776fd57084', //UID grasselli
    accountId: 'grasselli-preprod',
    // authenticationUrl: 'http://localhost:3020/',
    authenticationUrl: 'https://auth.datalean-nodejs-preprod.catalean.com/',
    configUrl: 'https://configuration.datalean-nodejs-preprod.catalean.com/',
    cataleanCacheUrl: 'https://catbnddev.addictionlab.it/v1/',
    productsUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/product/v2/',
    wishlistUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/wishlist/',
    collectionsUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/album/v2/',
    // collectionsUrl: 'http://localhost:3013/album/',
    usersUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/application/v2/',
    registrationUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/application/v2/register/',
    userStructureUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/structure/open/v2/',
    structuresUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/structure/v2/',
    organizationUrl: 'https://organization.datalean-nodejs-preprod.catalean.com/organization/',
    shareLinkUrl: 'https://translator.datalean-nodejs-preprod.catalean.com/share/',
    shareLinkParseUrl: 'https://translator.datalean-nodejs-preprod.catalean.com/share/parse',
    defaultLocale: 'en-US',
    version: '0.0.0',
    availableLocales: ['en-US','it-IT','es-ES'],
    templateUrl: 'https://translator.datalean-nodejs-preprod.catalean.com/template/',
    // mappingUUID: '41350706-b3db-4f0d-bb30-60d34ec5ae58',
    uuidParameterType: 'MAP_CONTAINER_UUID',
    // 1: normal logout, 2: overwrite check assets to delete on next login, 3: delete assets on logout
    logoutDataRetentionMode: 1,
    resourceBatchLimit: 10,
    catalean: {
      version: 1,
      uuid: '2a2b5733-8658-4127-89ae-07b05a611743',
    },
    userStructureUUID: '14dba8dd-0fb3-4fbb-8322-43182f4a97b9'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  