export enum Parts {
  ALL = '*',
  EMPTY = '',
  STRUCTURE = 'structures',
  SENSITIVE_CONFIGURATIONS = 'allWithSensitive',
  AUDIT = 'audit',
  VALUES = 'values',
  CHILDREN = 'children',
  FEATURE_VALUES = 'featureValues',
  WISHLISTS = 'wishlists',
  FEATURE_VALUE_LIST = 'featureValueList',
  ROLES = 'roles',
  ADMIN = 'admin',
  CUSTOMIZATIONS = 'customizations',
  MEMBERS = 'members',
  PERMISSIONS = 'permissions',
  AUTHENTICATION_METHODS = 'authenticationMethods',
  COMMUNITY = 'communityUUID',
  DEBITS = 'debits',
  TOPUPS = 'topups',
  RESPONSIBLE = 'responsible',
  STRUCTURE_FIELDS = 'structureFields',
  COMMUNITIES = 'communities',
  LATEST_ACCESS = 'latest_access',
  LATEST_LOGIN = 'latest_login',
  ACCESS_COUNT = 'access_count',
  LOGIN_COUNT = 'login_count',
  MEMBERCOUNTS = 'membersCount',
  PRODUCTS = 'products',
  COMMUNICATIONS = 'communications',
  ATTACHMENTS = 'attachments',
  RECIPIENTS = 'recipients',
  HAS_OPENED = 'hasOpened',
  LIKES = 'likes',
  HAS_CONFIRMED = 'hasConfirmed',
  RESET_DEVICE_IDENTIFIER = 'resetDeviceIdentifier',
  PARTICIPANT_COUNT = 'participantsCount',
  SCORE = 'score',
  QUESTION = 'questions',
  SHARE_DATA = 'shareData',
}
