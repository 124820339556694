<ion-header>
  <button (click)="closeModal()"></button>
</ion-header>
<div>
  <div class="privacy-policy-left-container">
    <h1 class="title">{{"PRIVACY_POLICY_PAGE.TITLE" | translate}}</h1>
    <div [innerHTML]="text"></div>
  </div>
  <div class="privacy-policy-right-container">
    <span>powered by</span>
    <br>
    <button (click)="openCataleanWebsite()" class="credits">
      <span>www.catalean.com</span>
      <br>
      <span>{{version}}</span>
    </button>
    <button *ngIf="this.userDeletion" [title]="'PRIVACY_POLICY_PAGE.DELETE_USER' | translate" (click)="deleteUserMail()" class="button filled">
      {{'PRIVACY_POLICY_PAGE.DELETE_USER' | translate}}
    </button>
  </div>
</div>
