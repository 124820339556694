import { DataleanDataProviderService } from 'catalean-provider';
import { UserSettingsService } from './services/user-settings-service.service';
import {OAuthErrorEvent, OAuthService} from "angular-oauth2-oidc";
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Router} from "@angular/router";
import { AuthenticationToken,
  EnvironmentConfiguration,
  User
} from 'catalean-models';
import {catchError, filter, map} from 'rxjs/operators';
import { UserDataService } from './services/user-data-service.service';
import { NavController, Platform } from '@ionic/angular';
import { NetworkStateService } from './services/network-state-service';
import { CurrentPlatformService } from 'src/app/services/current-platform.service';


@Injectable({
  providedIn: 'root'
})
export class CataleanAuthenticationService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  isOnline?:boolean;

  constructor( @Inject('env') private environmentSettings: EnvironmentConfiguration,
               private dataleanServiceProvider: DataleanDataProviderService,
               public oauthService: OAuthService,
               private router: Router,
               private navCtrl: NavController,
               private userSettingService: UserSettingsService,
               private network: NetworkStateService,
               private userDataService: UserDataService,
               private platform:CurrentPlatformService
               ) {
    this.network.isOnline.subscribe(is => {
      if(!this.isOnline && is === true && !this.platform.is('electron')) {
        this.oauthService.setupAutomaticSilentRefresh()
      }
      this.isOnline = is;
    })

    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent && navigator.onLine) {
        console.error('OAuthErrorEvent Object:', event);
        if(this.isOnline) {
          this.navigateToLoginPage()
        }
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    });


    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error', ''].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    this.oauthService.events
      .pipe(filter(e => ['token_revoke_error', 'token_refresh_error'].includes(e.type)))
      .subscribe(e => {
        if(this.isOnline) {
          this.logout()
        }
      });

    // this.oauthService.setupAutomaticSilentRefresh();
  }

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.navCtrl.navigateRoot(['login']);
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
      //.then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1000)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this.oauthService.tryLogin())

      .then(() => {
        if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        return this.oauthService.refreshToken()
          .then(() => Promise.resolve())
          .catch(result => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.login();
              //
              // Instead, we'll now do this:
              console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })

      .then(() => {
        const token = new AuthenticationToken(this.oauthService.getIdToken(), undefined);
        const userUUID = token.getPayloadParameter('userUUID');

        // if (!this.userDataService.getAuthenticationTokenInstant()) this.store.dispatch(insertRequest({request: {action: ContestActionType.LOGIN}}));
        this.dataleanServiceProvider.getUserWithUUID(userUUID)
          .pipe(
            catchError((authError) => {
              return of(undefined);
            })
          )
          .subscribe((userData) => {
            this.userDataService.setAuthenticatedUser(userData as User);
            this.isDoneLoadingSubject$.next(true);
          });

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.userSettingService.init();
          this.userSettingService.authUserFilled.subscribe(data => this.router.navigate([stateUrl]));
        }
      })
      .catch(() => {
        this.isDoneLoadingSubject$.next(true)
      });
  }

  getUserData(userUUID: string): Observable<User> {
    return this.dataleanServiceProvider.getUserWithUUID(userUUID).pipe(
      map((data) => {
        this.userDataService.setAuthenticatedUser(data as User);
        return data as User;
      })
    );
  }

  login(url: string | undefined) {
    if(this.platform.is('electron')) {
      this.oauthService.initLoginFlowInPopup().then(()=> {
        this.userSettingService.init();
      })
    } else {
      this.oauthService.initLoginFlow(url)
    }
  }

  public async logout() {
    await this.oauthService.revokeTokenAndLogout();
    this.clearStorage()
  }

  private clearStorage() {
    localStorage.clear();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public get idToken() {
    return this.oauthService.getIdToken();
  }

  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }

  // changePassword(password: string): Observable<any> {
  //   const changePasswordSubject = new Subject();
  //   const userData: User = this.userSettingService.getAuthenticatedUserData();
  //   const authenticationMethod = {
  //     'username': userData.Username,
  //     'password': password,
  //     'type': 'USERNAME_PASSWORD'
  //   };
  //   this.dataleanServiceProvider.putUserDataWithAuthenticationMethod(userData, authenticationMethod).subscribe((data) => {
  //     changePasswordSubject.next({});
  //   },
  //   (error) => {
  //     changePasswordSubject.error(error);
  //   });
  //   return changePasswordSubject.asObservable();
  // }
}
