import { Filter } from './Filter';
export interface FeatureValue {
  uuid: string,
  name: string,
  label: string,
  iconURL: string | {
    url: string;
    name?: string;
    altText?: string;
    previewURL?: string;
  },
  type: string | string[],
  filterOptions: Filter,
  order:number,
  featureValues: FeatureValue[],
  imageURL?: {
    url: string;
    name?: string;
    altText?: string;
    previewURL?: string;
  },
  parentUUID?: string;
}
