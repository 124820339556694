import {UserDataService} from '../services/user-data-service.service';
import {Component, OnInit, Output, EventEmitter, Input, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CataleanAuthenticationService} from '../catalean-authentication.service';
import {AuthFeedbackService} from "../services/auth-feedback-service";
import { skipWhile, first } from 'rxjs/operators';
import {User} from 'catalean-models';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';

@Component({
  selector: 'cat-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() successfullLogin = new EventEmitter<boolean>();
  @Output() registrationClick = new EventEmitter<void>();
  @Output() tapRecoveryPassword = new EventEmitter<void>();
  loginForm: UntypedFormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });
  loading = false;
  submitted = false;
  returnUrl: string;
  invalidAuth = false;
  @Input() cacheError = null;
  @Input() usernamePlaceholder = '';
  @Input() passwordPlaceholder = '';

  @Input() registration = false;
  @Input() alreadyLogged = false;
  @Input() activateProgressBar = false;
  @Input() progressBarPercentage = 0;
  @Input() loaderDetailText = '';
  @Input() recoveryPass = false;
  @Input() mailSupport = '';
  @Input() dataLoaded = false;
  @ViewChild('inputUserName') inputUserName;
  @ViewChild('inputUserPassword') inputUserPassword;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authenticationService: CataleanAuthenticationService,
    private userDataService: UserDataService,
    private authFeedbackService: AuthFeedbackService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.submitted = false;
    this.invalidAuth = false;
    const token = this.userDataService.getAuthenticationTokenInstant();
    if (token) {
      this.authenticationService.getUserData(token.getPayloadParameter('userUUID')).subscribe({
        next: (user: User) => {
          if (user.isCataleanUser) {
            this.successfullLogin.emit(true);
          } else {
            this.successfullLogin.emit(false);
          }
        },
        error: (errorMessage: HttpErrorResponse) => {
          if (errorMessage.status != HttpStatusCode.Unauthorized && errorMessage.status != HttpStatusCode.Forbidden
            && this.userDataService.getAuthenticatedUserInstant()) {
            this.successfullLogin.emit(true);
          } else {
            this.successfullLogin.emit(false);
          }
        },
      });
    } else {
      this.loginForm.reset();
    }
  }

  // convenience getter for easy access to form fields
  get form() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.invalidAuth = false;
    this.authenticationService.isDoneLoading$
      .pipe(skipWhile((x) => !x), first())
      .subscribe((doneLoading) => {
        this.authenticationService.login(this.router.url);
      });
  }

  onRegistrationClick() {
    this.registrationClick.emit();
  }

  tapRecovery() {
    this.tapRecoveryPassword.emit();
  }

}
