import { CataleanModelsModule } from 'catalean-models';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HtmlConverter } from './pipes/html-converter.pipe';
import { CataleanLocalizationModule } from 'catalean-localization';
import { ToLocalizedValuePipe } from './pipes/to-localized-value.pipe';

@NgModule({
  declarations: [HtmlConverter, ToLocalizedValuePipe],
  imports: [HttpClientModule, CataleanModelsModule, CataleanLocalizationModule],
  exports: [HtmlConverter, ToLocalizedValuePipe],
})
export class CataleanProviderModule {
  public static forRoot(env): ModuleWithProviders<any> {
    return {
      ngModule: CataleanProviderModule,
      providers: [{ provide: 'env', useValue: env }],
    };
  }
}
