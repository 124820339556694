import { Injectable } from '@angular/core';
import { Resource } from 'catalean-models';
import { CataleanStorageService } from 'catalean-storage';

@Injectable({
  providedIn: 'root',
})
export class LocalResourcesService {

  private localResourcesMap: {[remoteUrl: string]: Resource} = {};

  constructor(private storage: CataleanStorageService) {}

  async init() {
    this.localResourcesMap = {};
    const storageResources = (await this.storage.getAllResources()) ?? {};
    if (Object.keys(storageResources).length > 1) {
      this.localResourcesMap = storageResources;
    }
  }
  

  getLocalResourceByUrl(url: string): Resource | undefined {
    return this.localResourcesMap[url];
  }
  setLocalResourceByUrl(url: string, resource:Resource): void {
    this.localResourcesMap[url] = resource;
  }

  async getLocalResourceByUrlFromStorage(url: string): Promise<Resource | undefined> {
    const resourceObjAsString = await this.storage.getResource(url);
    try {
      if (resourceObjAsString) {
        console.log('FOUND %s', url);
        return JSON.parse(resourceObjAsString) as Resource;
      } else {
        // console.log('resource >%s< NOT FOUND into storage', url);
        return undefined;
      }
    } catch (error) {
      console.log('error getting resource >%s< from storage', url);
      return undefined;
    }
  }

  async addOrUpdateStorageResource(remoteResourceUrl: string, resource: Resource):Promise<any> {
    return this.storage.addOrUpdateResource(remoteResourceUrl, JSON.stringify(resource));
  }
}
