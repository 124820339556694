import { Observable, zip, of, from, defer, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  FilterManagerService,
  ResourceManagerService,
  FeatureManagerService,
  StructureManagerService,
  ContentManagerService,
} from 'catalean-provider';
import { ProductManagerService } from 'catalean-provider';
import { CataleanCacheDataProviderService } from 'catalean-provider';
import { switchMap, tap, catchError, map, filter } from 'rxjs/operators';
import { CataleanLocalizationService } from 'catalean-localization';
import { CurrentPlatformService } from 'src/app/services/current-platform.service';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root',
})
export class CataleanLocalFilesService {
  private activeLanguage: string;

  constructor(
    private platform: CurrentPlatformService,
    private dataProvider: CataleanCacheDataProviderService,
    private productManager: ProductManagerService,
    private resourceManager: ResourceManagerService,
    private contentManager: ContentManagerService,
    private featureManager: FeatureManagerService,
    private structureManager: StructureManagerService,
    public cataleanLocalizationService: CataleanLocalizationService
  ) {
    this.cataleanLocalizationService.ObserveActiveLang.subscribe((lan: string) => {
      if (lan) this.activeLanguage = lan;
    });
  }

  private mergeObjectArray(a, b, p) {
    return a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b);
  }

  private loadJson(replace: boolean, isProduct: boolean, userUUID?: string): Observable<any> {
    const filter = isProduct ? this.dataProvider.CATALEAN_CACHE_PRODUCTS : this.dataProvider.CATALEAN_CACHE_FILTER;

    if (replace) {
      const versionCallList: Observable<{ version: string; locale: string }>[] = [...this.cataleanLocalizationService.AvailableLocales].map(
        (locale) =>
          this.dataProvider.getJsonVersion(filter, locale, userUUID).pipe(
            switchMap((version: string) => {
              this.checkLocalVersion(version, filter, locale, true)
                .then(() => console.log(`replaced ${filter} ${locale} version`))
                .catch((err) => console.log(err));
              return of({ version, locale });
            })
          )
      );
      return zip(...versionCallList).pipe(
        switchMap((items) => {
          const productCallList = items.map((item) => {
            let call = this.dataProvider.getFiltersJson(item.version, item.locale, userUUID);
            if (isProduct) {
              call = this.dataProvider.getProductsJson(item.version, item.locale, userUUID);
            }
            return call.pipe(
              switchMap((json: string) => {
                return defer(() =>
                  from(
                    Filesystem.writeFile({
                      path: `${filter}_${item.locale}.json`,
                      directory: Directory.Data,
                      data: encodeURI(JSON.stringify(json)),
                      encoding: Encoding.UTF8
                    })
                  ).pipe(map(() => json))
                );
              })
            );
          });
          return zip(...productCallList);
        }),
        tap((jsonArray) => {
          console.log('completed writes');
          console.log(jsonArray);
        }),
        catchError((error) => {
          console.log('failed writes');
          console.log(error);
          return defer(() => from(this.readTextAsJSON(isProduct)));
        })
      );
    } else {
      return defer(() => from(this.readTextAsJSON(isProduct)));
    }
  }

  /**
   * @param  {Subject<any>} filterArraySubject
   * @param  {boolean} isProduct
   */
  private async readTextAsJSON(isProduct: boolean): Promise<any[]> {
    const filter = isProduct ? this.dataProvider.CATALEAN_CACHE_PRODUCTS : this.dataProvider.CATALEAN_CACHE_FILTER;
    const result: string[] = [];
    for (const locale of this.cataleanLocalizationService.AvailableLocales) {
      const fileName = `${filter}_${locale}.json`;
      const promiseResult: string = (await Filesystem.readFile({ path: fileName, directory: Directory.Data, encoding: Encoding.UTF8 })).data as string;
      result.push(JSON.parse(decodeURI(promiseResult)));
    }
    return result;
  }

  /**
   *
   * @param version version
   * @param type either Product or Filter
   * @param replace replace current saved file
   * @returns resolve true is current version is the same of argument version,
   *          reject if file operations fail
   */
  private async checkLocalVersion(version: string, type: string, locale: string, replace: boolean): Promise<boolean> {
    const fileName = `${type}_${locale}_Version.txt`;

    try {
      const readResult = await Filesystem.readFile({
        path: fileName,
        directory: Directory.Data,
        encoding: Encoding.UTF8
      });
      if (type === this.dataProvider.CATALEAN_CACHE_PRODUCTS) {
        localStorage.setItem('lastProductVersion', readResult.data as string);
      }
      if (readResult.data === version) {
        return true;
      }
      if (!replace) {
        return false;
      }
      try {
        const writeResult = await Filesystem.writeFile({
          directory: Directory.Data,
          path: fileName,
          data: version,
          encoding: Encoding.UTF8
        });
        if (type === this.dataProvider.CATALEAN_CACHE_PRODUCTS) {
          localStorage.setItem('lastProductVersion', version);
        }
        return true;
      } catch (err) {
        return false;
      }
    } catch (err) {
      if (!replace) {
        return false;
      }
      try {
        const writeResult = await Filesystem.writeFile({
          directory: Directory.Data,
          path: fileName,
          data: version,
          encoding: Encoding.UTF8
        });
        if (type === this.dataProvider.CATALEAN_CACHE_PRODUCTS) {
          localStorage.setItem('lastProductVersion', version);
        }
        return true;
      } catch (err) {
        return false;
      }
    }
  }

  init(replace: boolean, userUUID?: string): Observable<boolean> {
    return defer(() =>
      from(this.platform.ready()).pipe(
        switchMap(() => {
          return combineLatest(
            [this.loadJson(replace, true, userUUID), this.loadJson(replace, false, userUUID)] /*, this.loadExtraItems()*/
          );
        }),
        filter(([filter, products]) => {
          return !!filter[0] && !!products[0];
        }),
        map((jsonArray) => {
          // salvataggio dati localizzati in memoria
          const localeIndex = this.cataleanLocalizationService.AvailableLocales.indexOf(this.activeLanguage);
          const item = jsonArray[0][localeIndex];

          let resourcesToSet = [...item.resources];
          if (jsonArray[0].length > 1) {
            //rimuovo quello attivo perchè sarà il primo da mettere in resourceArray
            [...jsonArray[0]]
              .filter((v, i) => !!v && localeIndex != i)
              .map((el) => (resourcesToSet = this.mergeObjectArray(resourcesToSet, el.resources, 'url')));
          }
          this.productManager.mapAndSaveProducts(item.products);
          // this.filterManager.mapAndSaveFilters(jsonArray[1][localeIndex]);
          this.featureManager.mapAndSaveFeatures(jsonArray[1][0].filters);
          this.contentManager.mapAndSaveContent(item.content);
          // this.featureManager.mapAndSaveFeatures(item.featureValues);
          this.structureManager.mapAndSaveStructures(item.structures);
          // TODO valutare se mettere in download tutti gli assets di tutte le lingue o solo una lingua
          this.resourceManager.mapAndSaveResources(resourcesToSet);

          // TODO Scrittura file in storage solo se necessario ie file non presente o versione diversa
          return true;
        })
      )
    );
  }

  checkVersionAvailabilty(userUUID?: string): Observable<boolean> {
    return this.dataProvider.getJsonVersion(this.dataProvider.CATALEAN_CACHE_PRODUCTS, this.activeLanguage, userUUID).pipe(
      switchMap((version: string) => {
        return defer(() => from(this.checkLocalVersion(version, this.dataProvider.CATALEAN_CACHE_PRODUCTS, this.activeLanguage, false)));
      })
    );
  }
}
