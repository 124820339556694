import { DataleanDataProviderService } from 'catalean-provider';
import {Injectable} from '@angular/core';
import { AuthenticationToken } from 'catalean-models';
import { User, UserBuilder } from 'catalean-models';
import {catchError} from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {

  private authenticationToken: AuthenticationToken;
  private authenticatedUser: User;
  public authUserFilled = new Subject<any>();

  private readonly USER_LOCALE_STORAGE_KEY: string = 'userLocale';
  private readonly AUTHENTICATION_TOKEN_STORAGE_KEY: string = 'id_token';
  private readonly NOTIFICATION_TOKEN_STORAGE_KEY: string = 'notificationToken';
  private readonly PRIVACY_SETTINGS_STORAGE_KEY: string = 'privacySettings';
  private readonly AUTHENTICATED_USER_STORAGE_KEY: string = 'authenticatedUser';
  private readonly USER_AVATAR_IMAGE_STORAGE_KEY_PREFIX: string = 'userAvatarImage';

  constructor(private dataleanServiceProvider: DataleanDataProviderService) {
  }

  init() {
    if (this.getStoredToken()) {
      this.saveAuthenticationToken(this.getToken());
    }
    if (this.getStoredAuthenticatedUserData()) {
      this.saveAuthenticatedUserData(this.getStoredAuthenticatedUserData());
      this.authUserFilled.next(this.getStoredAuthenticatedUserData());
    }
    else {
      if(this.getToken() && this.getToken().getPayloadParameter("userUUID")) {
        this.dataleanServiceProvider.getUserWithUUID(this.getToken().getPayloadParameter("userUUID"))
          .pipe(
            catchError((authError) => {
              return of(undefined);
            })
          )
          .subscribe((userData) => {
            this.saveAuthenticatedUserData(userData as User);
            this.authUserFilled.next(userData);
          });
      }
    }
  }

  storeUserLocale(locale: string) {
    window.localStorage.setItem(this.USER_LOCALE_STORAGE_KEY, locale);
  }

  fetchUserLocale(): string {
    return this.getStoredUserLocale();
  }

  saveAuthenticationToken(authenticationToken: AuthenticationToken) {
    this.authenticationToken = authenticationToken;
  }

  public resetLoggedUserTokens() {
    window.localStorage.removeItem(this.AUTHENTICATION_TOKEN_STORAGE_KEY);
    window.localStorage.removeItem(this.AUTHENTICATED_USER_STORAGE_KEY);
    this.authenticationToken = undefined;
    this.authenticatedUser = undefined;
  }

  getStoredToken() {
    const storedToken = window.localStorage.getItem(this.AUTHENTICATION_TOKEN_STORAGE_KEY);
    const storedExp = window.localStorage.getItem('id_token_expires_at');
    return (storedToken !== null) ? new AuthenticationToken(storedToken, storedExp) : undefined;
  }

  getToken(): AuthenticationToken {
    return this.authenticationToken ? this.authenticationToken : this.getStoredToken();
  }

  getUserUUID(): string {
    return this.authenticationToken ? this.authenticationToken.getPayloadParameter('userUUID') : undefined;
  }

  getNotificationToken(): string {
    return window.localStorage.getItem(this.NOTIFICATION_TOKEN_STORAGE_KEY);
  }

  private getStoredUserLocale() {
    return window.localStorage.getItem(this.USER_LOCALE_STORAGE_KEY);
  }

  saveAuthenticatedUserData(authenticatedUser: User) {
    this.authenticatedUser = authenticatedUser;
    window.localStorage.setItem(this.AUTHENTICATED_USER_STORAGE_KEY, JSON.stringify(this.authenticatedUser));
  }

  getStoredAuthenticatedUserData() {
    const storedAuthenticatedUser = JSON.parse(window.localStorage.getItem(this.AUTHENTICATED_USER_STORAGE_KEY));
    return (storedAuthenticatedUser !== null) ? new UserBuilder(
        storedAuthenticatedUser.id,
        storedAuthenticatedUser.firstName,
        storedAuthenticatedUser.lastName,
        storedAuthenticatedUser.username
      )
        .withAuthenticationMethods(storedAuthenticatedUser.authenticationMethods)
        .withEmail(storedAuthenticatedUser.email)
        .withAvatarImageURL(storedAuthenticatedUser.avatarImageURL)
        .withResetDeviceIdentifier(storedAuthenticatedUser.resetDeviceIdentifier)
        .withStructureUUID(storedAuthenticatedUser.structureUUID)
        .withCataleanUser(storedAuthenticatedUser.cataleanUser)
        .build()
      : undefined;
  }

  getAuthenticatedUserData(): User {
    return this.authenticatedUser;
  }


  getAvatarPathForUser(userId: string) {
    return window.localStorage.getItem(this.getUserAvatarImageStorageKey(userId));
  }

  private getUserAvatarImageStorageKey(userId: string) {
    return this.USER_AVATAR_IMAGE_STORAGE_KEY_PREFIX + '_' + userId;
  }
}
