<ion-app>
  <ion-menu [maxEdgeStart]="0" class="my-custom-menu" side="end" menuId="catalean-menu" (ionWillOpen)="onMenuOpen()" contentId="menu">
    <cat-download-progress *ngIf="platform.is('cordova')" [update]="openMenuSubject" [width]="80" class="download-container">
      <div class="icon-pause" type="pause"></div>
      <div class="icon-play" type="play"></div>
    </cat-download-progress>
    <cat-hamburger-menu
      *ngIf="rootFilter$ | async as rootFilter"
      [onMenuOpen]="openMenuSubject"
      (filterSelected)="goTo($event)"
      [rootFilter]="rootFilter"
      [filters]="rootFilterChildren$ | async"
      (clickLogout)="logout()"
      [disableDeepNavigationFilter]="true"
      [buttonTemplate]="buttonMenuTemplate"
      [activeFilter]="activeFilter$ | async"
    >
    </cat-hamburger-menu>
    <cat-localization-selector></cat-localization-selector>
    <div class="app-version">
      <span>{{ version }}</span>
    </div>
  </ion-menu>
  <div class="mobile" *ngIf="isMobile">
    <div class="mobile-message">
      <img class="image" src="./assets/images/min-width.png" />
      <h2>{{ 'NO_MOBILE.HEADER' | translate }}</h2>
      <p>{{ 'NO_MOBILE.CONTENT' | translate }}</p>
    </div>
  </div>
  <ion-router-outlet [swipeGesture]="false" id="menu"></ion-router-outlet>
</ion-app>

<ng-template #buttonMenuTemplate>
  <div class="description-container">
    <button (click)="openCollections()">{{ 'HAMBURGER_MENU.COLLECTIONS' | translate }}</button>
  </div>
</ng-template>
