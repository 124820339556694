import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CataleanModelsModule} from 'catalean-models';
import {CataleanHamburgerMenuComponent} from './catalean-hamburger-menu/catalean-hamburger-menu.component';
import {IonicModule} from '@ionic/angular';
import {CataleanProviderModule} from 'catalean-provider';
import {PrivacyModalComponent} from './privacy-modal/privacy-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

@NgModule({
    declarations: [CataleanHamburgerMenuComponent, PrivacyModalComponent],
    imports: [
        CommonModule,
        CataleanModelsModule,
        IonicModule,
        CataleanProviderModule,
        TranslateModule,
        SwiperModule
    ],
    exports: [CataleanHamburgerMenuComponent]
})
export class CataleanHamburgerMenuModule {
  public static forRoot(version): ModuleWithProviders<any> {
    return {
      ngModule: CataleanHamburgerMenuModule,
      providers: [{provide: 'version', useValue: version}],

    };
  }
}
