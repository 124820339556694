/*
 * Public API Surface of catalean-local-files
 */

export * from './lib/catalean-local-files.service';
export * from './lib/catalean-local-files.module';
export * from './lib/device-resources.service';
export * from './lib/local-resources.service';

export * from './lib/components';

export * from './lib/pipe/to-local-resource.pipe';
