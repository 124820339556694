import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SmartHttpModuleService {
  constructor(private http: HttpClient) {}
  get<T>(url: string, responsetype?): Observable<T> {
    if (responsetype) {
      return this.http.get<T>(url, { responseType: responsetype });
    }
    return this.http.get<T>(url);
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.http.post<any>(url, data);
  }

  put<T>(url: string, data: any): Observable<T> {
    return this.http.put<any>(url, data);
  }

  delete(url: string, body?: any): Observable<any> {
    if (body) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body,
      };
      return this.http.delete<any>(url, options);
    } else return this.http.delete<any>(url);
  }
}
