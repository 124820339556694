export class UserBuilder {
  private email: string;
  private avatarImageURL: string;
  private resetDeviceIdentifier = false;
  private structureUUID: string;
  private authenticationMethods: any;
  private cataleanUser: boolean;

  constructor(private id: string, private firstName: string, private lastName: string, private username: string) {
  }

  get Id() {
    return this.id;
  }

  get isCataleanUser() {
    return this.cataleanUser;
  }

  get FirstName() {
    return this.firstName;
  }

  get LastName() {
    return this.lastName;
  }

  get Username() {
    return this.username;
  }

  get Email() {
    return this.email;
  }

  get AvatarImageURL() {
    return this.avatarImageURL;
  }

  get ResetDeviceIdentifier() {
    return this.resetDeviceIdentifier;
  }

  get StructureUUID() {
    return this.structureUUID;
  }

  get AuthenticationMethods() {
    return this.authenticationMethods;
  }

  withEmail(email: string): UserBuilder {
    this.email = email;
    return this;
  }

  withAvatarImageURL(avatarImageURL: string): UserBuilder {
    this.avatarImageURL = avatarImageURL;
    return this;
  }

  withResetDeviceIdentifier(resetDeviceIdentifier: boolean): UserBuilder {
    this.resetDeviceIdentifier = resetDeviceIdentifier;
    return this;
  }

  withStructureUUID(structureUUID: string): UserBuilder {
    this.structureUUID = structureUUID;
    return this;
  }

  withUsername(username: string): UserBuilder {
    this.username = username;
    return this;
  }

  withCataleanUser(cataleanUser: boolean): UserBuilder {
    this.cataleanUser = cataleanUser;
    return this;
  }

  withAuthenticationMethods(authenticationMethods: string): UserBuilder {
    this.authenticationMethods = authenticationMethods;
    return this;
  }

  build() {
    return new User(this);
  }
}

export class User {
  static readonly UUID_FIELD_KEY = 'uuid';
  static readonly FIRST_NAME_FIELD_KEY = 'firstName';
  static readonly LAST_NAME_FIELD_KEY = 'lastName';
  static readonly USERNAME_FIELD_KEY = 'username';
  static readonly EMAIL_FIELD_KEY = 'email';
  static readonly AVATAR_IMAGE_URL_FIELD_KEY = 'avatarImageURL';
  static readonly RESET_DEVICE_IDENTIFIER_FIELD_KEY = 'resetDeviceIdentifier';
  static readonly STRUCTURE_UUID_FIELD_KEY = 'structureUUID';
  static readonly VALUES_FIELD_KEY = 'values';
  static readonly AUTH_METHODS_FIELD_KEY = 'authenticationMethods';
  static readonly CATALEAN_USER_FIELD_KEY = 'cataleanUser';

  private readonly id: string;
  private readonly firstName: string;
  private readonly cataleanUser: boolean;
  private readonly lastName: string;
  private readonly username: string;
  private readonly email: string;
  private readonly avatarImageURL: string;
  private readonly resetDeviceIdentifier: boolean;
  private readonly structureUUID: string;
  private readonly authenticationMethods: any;

  constructor(userBuilder: UserBuilder) {
    this.id = userBuilder.Id;
    this.firstName = userBuilder.FirstName;
    this.cataleanUser = userBuilder.isCataleanUser;
    this.lastName = userBuilder.LastName;
    this.username = userBuilder.Username;
    this.email = userBuilder.Email;
    this.avatarImageURL = userBuilder.AvatarImageURL;
    this.resetDeviceIdentifier = userBuilder.ResetDeviceIdentifier;
    this.structureUUID = userBuilder.StructureUUID;
    this.authenticationMethods = userBuilder.AuthenticationMethods;
  }

  get Id() {
    return this.id;
  }

  get FirstName() {
    return this.firstName;
  }

  get LastName() {
    return this.lastName;
  }

  get isCataleanUser() {
    return this.cataleanUser;
  }

  get Username() {
    return this.username;
  }

  get Email() {
    return this.email;
  }

  get AvatarImageURL() {
    return this.avatarImageURL;
  }

  get ResetDeviceIdentifier() {
    return this.resetDeviceIdentifier;
  }

  get StructureUUID() {
    return this.structureUUID;
  }

  get AuthenticationMethods() {
    return this.authenticationMethods;
  }

}
