import { Inject, Injectable } from '@angular/core';
import { DataleanDataProviderService } from 'catalean-provider';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';
import { Integration, EnvironmentConfiguration, CataleanAppConfig} from 'catalean-models';
import { HttpErrorResponse } from '@angular/common/http';
import { CataleanStorageService } from 'catalean-storage';

interface Catalean {
  version: number;
  uuid: string;
  downloadLinkIos: string;
  downloadLinkAndroid: string;
  downloadLinkElectron: string;
  mimeTypeElectron: string;
  androidAutoUpdate: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CataleanConfigService<T extends Integration = Integration> {
  private catalean = new BehaviorSubject<T>(undefined);

  constructor(
    @Inject('env') private environment: EnvironmentConfiguration,
    private baseApi: DataleanDataProviderService,
    private persistedDataManagerService: CataleanStorageService
  ) {
  }
  init(): void {
    this.baseApi.getEntities<T>(`${this.environment.configUrl}integration/${this.environment.catalean.uuid}`, undefined, []).pipe(
      catchError((error: HttpErrorResponse) => {

        return from(this.persistedDataManagerService.getRow<T>("config"));
      }))
      .subscribe((configRow: T | null) => {
        if (configRow) {
          this.catalean.next(configRow);
          this.persistedDataManagerService.setRow("config", configRow);
        }
      }
      )
  }
  getCataleanValue() {
    return this.catalean.value;
  }
  getCatalean() {
    return this.catalean.pipe(filter(c => c != undefined));
  }
  getConfigByName(name: string): Observable<T[keyof T]> {
    return this.getCatalean().pipe(map(i => i[name]));
  }

}
