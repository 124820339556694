import { Injectable } from '@angular/core';
import { Structure } from 'catalean-models';

@Injectable({
  providedIn: 'root',
})
export class StructureManagerService {
  constructor() {}

  private structures: Structure[] = [];

  get Structures(): Structure[] {
    return this.structures;
  }

  mapAndSaveStructures(structureArray: Structure[]) {
    if (Array.isArray(structureArray)) {
      this.structures = [...structureArray];
    }
  }

  getStructure(id): Structure | undefined {
    return this.structures.find((structure) => structure.uuid == id);
  }
}
