import { NgModule } from '@angular/core';
import { CataleanLocalizationComponent } from './catalean-localization.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CataleanStorageModule } from 'catalean-storage';

@NgModule({
  declarations: [CataleanLocalizationComponent],
  imports: [CommonModule, TranslateModule, CataleanStorageModule],
  exports: [CataleanLocalizationComponent],
  providers: [TranslateService],
})
export class CataleanLocalizationModule {}
