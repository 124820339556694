import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { DeviceResourcesService } from '../../device-resources.service';

@Component({
  selector: 'cat-download-progress',
  templateUrl: './download-progress.component.html',
  styleUrls: ['./download-progress.component.scss'],
})
export class DownloadProgressComponent implements OnInit {
  @Input() width: number;
  @Input() update?: Subject<any>;

  debugMode = false;
  debugModeClicks = 0;
  percentage = 0;
  offset = 0;
  circumference: number;

  constructor(public deviceResources: DeviceResourcesService) {}

  ngOnInit(): void {
    this.circumference = this.width * Math.PI;
    if (this.update) {
      this.update.subscribe({
        next: () => this.updateValues(),
      });
    }
    this.deviceResources.fileDownloaded.pipe(auditTime(1000)).subscribe({
      next: () => this.updateValues(),
    });
  }

  private updateValues() {
    this.percentage =
      (this.deviceResources.Resources.length -
        this.deviceResources.ResourcesToBeDownloadedCount -
        this.deviceResources.FailedDownloadsCount) /
      this.deviceResources.Resources.length;
    this.offset = this.circumference - this.circumference * this.percentage + 31 * this.percentage;
  }

  toggleDownload() {
    if (this.deviceResources.StopDownload) {
      this.deviceResources.StopDownload = false;
      this.startDownload();
    } else {
      this.deviceResources.StopDownload = true;
    }
  }

  async startDownload(): Promise<void> {
    try {
      const counter = await this.deviceResources.doDownloadRemoteResources();
      console.log('download completed: >%s< downloaded resources', counter);
    } catch (error) {
      console.log('download resources error: %s', error);
    }
  }

  tryToEnableDebugMode() {
    if (this.debugModeClicks === 0) {
      setTimeout(() => {
        this.debugModeClicks = 0;
      }, 1000);
    }
    this.debugModeClicks++;
    if (this.debugModeClicks === 5) {
      this.debugMode = !this.debugMode;
    }
  }
}
