import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CataleanAuthenticationService } from 'catalean-authentication';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: CataleanAuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add authorization header with jwt token if available
    if (!request.url.includes(environment.authenticationUrl) && !request.url.includes(environment.shareLinkParseUrl)) {
      //if (this.authService.idToken /* && !request.url.includes('registration')*/) {
      request = request.clone({
        setHeaders: {
          // TODO add all needed with conditions
          // TimeZoneOffset: new Date().getTimezoneOffset().toString(),
          // Accept: 'application/json',
          'Content-Type': 'application/json',

          // Authorization:
          //   'Bearer ' +
          //   'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImMyMWJjNmE4YWZjM2NkOGU0OGQ4ZjEzYmQ5MjAwYjYxYmQ4ZmQ4NjUzNGFlNDRiNThjMGI0ODBhNTQ4YzZjZWEifQ.eyJzdWIiOiIzODUwNzQ5Zi0zN2MxLTQ1MWEtODQ2ZC00ZDg5ZWMwMTkyOGIuMDE4ZTFiNGItNmNiYy00MzcyLWIwN2ItOWFlMDU5MmIwYTU1LkFwcGxpY2F0aW9uVXNlckBjYXRhbGVhblVzZXIiLCJnaXZlbl9uYW1lIjoiaW5mbyBhZGRpY3Rpb24iLCJ1c2VybmFtZSI6ImluZm9AYWRkaWN0aW9uLml0IiwidXNlclVVSUQiOiIwMThlMWI0Yi02Y2JjLTQzNzItYjA3Yi05YWUwNTkyYjBhNTUiLCJvcmdhbml6YXRpb25VVUlEIjoiMzg1MDc0OWYtMzdjMS00NTFhLTg0NmQtNGQ4OWVjMDE5MjhiIiwidXNlclR5cGUiOiJBcHBsaWNhdGlvblVzZXIiLCJlbWFpbCI6ImluZm9AYWRkaWN0aW9uLml0IiwiYXV0aF90aW1lIjoxNjgxNzE1ODY4LCJub25jZSI6ImRUSXdMVzFKV1djeE4zVnBNMWd1ZUhaVGJrSlhYelU1WVg0M1RXdGhaV2hvUm14UmMyOXZXSFowUjFKSiIsImF0X2hhc2giOiJjMzVzWWMyZ0dqUnc2WlZWaXJIVmZRIiwiYXVkIjoiY2F2YW5uYS1pbnRlZ3JhdGlvbiIsImV4cCI6MTY5MDAwMDAwMCwiaWF0IjoxNjgxODg2MDU4LCJpc3MiOiJqYWNrIHBlciBmaWVyYSJ9.khXP438HyydDO8R_wcxosY-QWW4OdMk3xn-9ZEHbiUh8kTd-Gq1fguowIV_H_ZEuXRiVDFyXujZXcrqeg3mWD9roIWNTs8ljK5m6rEINcTvMr-rumLrjlooUyK2-NURYGVIVpbV2RF1zQu-3I6erwDSaNdQZikrCrJsSPugGlN_iPT5ZgwIgwgTCiF5TWrPvCjHRu5FPYukvFc2RPvciT6kQKVkQNxlRpbT3tMezk8Q9EwCYc02Ph9y235LDfBnuXApYiJRzxBNIzeSjpwkBfEQ4E01fpAfYieQq2s4MyW77JLNUo8MPa73fszw7RaPkZL-_Tbs9PSEZ39uiySguuA', //ELIMINARE SE LOGIN NON PRESENTE
          Authorization: 'Bearer ' + this.authService.idToken, //ELIMINARE SE LOGIN NON PRESENTE
        },
      });
      //}
    }
    return next.handle(request);
  }
}
