import {UserSettingsService} from 'catalean-authentication';
import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from "@angular/router";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private userSetting: UserSettingsService,
              private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(catchError(err => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.userSetting.resetLoggedUserTokens();
          this.router.navigate(['']);
        }
        return throwError(() => new Error(err));
      }));
  }
}
