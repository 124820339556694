import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'htmlConverter'})
export class HtmlConverter implements PipeTransform {

  constructor() {}

  transform(value: any): any {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    return doc.documentElement.textContent;  }
}
