<ion-content>
  <form class="recovery-container" [formGroup]="recoveryForm" (ngSubmit)="sendRequest()">
    <div class="logo"></div>
    <div class="title">{{'RECOVERY_PAGE.TITLE_RECOVERY_PAGE' | translate}}</div>
    <div class="description" *ngIf="!requestSent">{{'RECOVERY_PAGE.DESCRIPTION_RECOVERY_PAGE' | translate}}</div>
    <div class="recovery-form" *ngIf="!requestSent">
      <div class="username-container">
        <ng-container class='icon-container'>
          <ng-content select="smart-icon[type=username]"></ng-content>
        </ng-container>
        <div>
          <label>Username</label>
          <input #inputUserName type="text" formControlName="username" class="form-control" autocapitalize="off"
                 [ngClass]="{ 'is-invalid': (!isValid || requestJustSent || userNotPresent) && form.username.errors }" />
        </div>
      </div>
      <div *ngIf="!isValid || requestJustSent || userNotPresent" class="invalid-feedback">
        <div class="error-message" *ngIf="!isValid">{{'RECOVERY_PAGE.INVALID_MESSAGE' | translate}}</div>
        <div class="error-message" *ngIf="requestJustSent">{{'RECOVERY_PAGE.JUST_SENT' | translate}}</div>
        <div class="error-message" *ngIf="userNotPresent">{{'RECOVERY_PAGE.USER_NOT_PRESENT' | translate}}</div>
      </div>
      <div class="button-container">
        <button class="recovery-button" type="submit">{{'RECOVERY_PAGE.BUTTON_RECOVERY' | translate}}</button>
        <button class="login-button" (click)="this.tapLogin.emit()">{{'RECOVERY_PAGE.BUTTON_LOGIN' | translate}}</button>
      </div>
    </div>
    <div class="recovery-success" *ngIf="requestSent">
      <label class="success-label">{{'RECOVERY_PAGE.LABEL_SUCCESS' | translate}}</label>
      <button class="login-button" (click)="this.tapLogin.emit()">{{'RECOVERY_PAGE.BUTTON_LOGIN' | translate}}</button>
    </div>
  </form>
</ion-content>
