<div class='download-container'>
  <div class='progress-column-content' (click)="toggleDownload()">
    <div class='progress-column-graph'>
      <svg class='progress-ring' [attr.width]='width' [attr.height]='width'>
        <circle class='background-ring-circle' [attr.stroke-dasharray]="circumference + ',' + circumference"
          [attr.r]="width/2-5" [attr.cx]="width/2" [attr.cy]="width/2" />
        <circle class='progress-ring-circle' [attr.stroke-dasharray]="circumference + ',' + circumference" [attr.stroke-dashoffset]="offset"
          [attr.r]="width/2-5" [attr.cx]="width/2" [attr.cy]="width/2" />
      </svg>
      <div class='icon-container'>
        <ng-content *ngIf="deviceResources.StopDownload" select="div[type='play']"></ng-content>
        <ng-content *ngIf="!deviceResources.StopDownload" select="div[type='pause']"></ng-content>
      </div>
    </div>
  </div>
  <div class='text-container'>
    <div class="text" (click)="tryToEnableDebugMode()">
      {{"HAMBURGER_MENU.DOWNLOAD_CONTENTS" | translate}}
    </div>
    <div class="percent">
      {{ percentage * 100 | number : '1.0-0'}}
      <span>%</span>
    </div>
    <ng-container *ngIf="debugMode">
      <div class="text">
        {{(deviceResources.Resources.length - deviceResources.ResourcesToBeDownloadedCount) -
        deviceResources.FailedDownloadsCount}}/{{deviceResources.Resources.length}}
      </div>
      <div class="text">
        {{deviceResources.FailedDownloadsCount}}
      </div>
    </ng-container>

  </div>
</div>
