import { Injectable } from '@angular/core';
import { Product, Filter } from 'catalean-models';
import { FilterManagerService } from './filter-manager.service';
import { Observable } from 'rxjs';
import { EntityManagerService } from './entity-manager.service';
import { map, first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductManagerService extends EntityManagerService<Product> {
  constructor(filterService: FilterManagerService) {
    super(filterService);
  }

  getFilteredProducts(): Observable<Product[]> {
    return this.getFilteredEntities();
  }

  getProductsFilteredBySeletectMetro(): Observable<Product[]> {
    return this.filterService
      .getSelectedMetroFilter()
      .pipe(map((metroFilter) => this.Products.filter((p) => p.features.includes(metroFilter.filter.featureId))));
  }

  get Products(): Product[] {
    return this.Entities;
  }

  filterWouldHaveProductsIfAdded(filter: Filter): Observable<boolean> {
    return this.getFilteredProducts().pipe(
      first(),
      map((fps) => fps.some((p) => p.features.includes(filter.featureId)))
    );
  }

  getProduct(id): Product {
    return this.Products.find((product) => product.uuid === id);
  }

  resetProducts() {
    super.resetEntities();
  }

  mapAndSaveProducts(products: Product[]) {
    super.mapAndSaveEntites(products);
  }
}
