import { Injectable } from '@angular/core';
import { AlbumUpdateBody, Collection, CollectionSyncField, Resource } from 'catalean-models';
import { DataleanDataProviderService, ResourceManagerService } from 'catalean-provider';
import { CataleanStorageService } from 'catalean-storage';
import { BehaviorSubject, Observable, catchError, concat, first, forkJoin, from, map, of, switchMap, toArray } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CataleanCollectionService {
  readonly COLLECTIONS_STORAGE_KEY = 'collections';

  private status$ = new BehaviorSubject<'uninitialized' | 'loading' | 'error' | 'loaded'>('uninitialized');

  private collections$ = new BehaviorSubject<Collection[]>([]);

  private lastError$ = new BehaviorSubject<string>(undefined);

  isShareCollection = false;

  constructor(
    private cataleanStorage: CataleanStorageService,
    private dataleanServiceProvider: DataleanDataProviderService,
    private resourceManagr: ResourceManagerService
  ) {}

  //funzione utilizzata per gestire lo share
  setCollections(collections: Collection[]) {
    this.collections$.next(collections);
    this.isShareCollection = true;
  }

  getCollections() {
    return this.collections$.asObservable();
  }

  getCollectionsArray() {
    return this.collections$.getValue();
  }

  createFakeCollectionUUID(): string {
    return 'FAKE_UUID_GRASSELLI' + new Date();
  }

  private setError(e): void {
    this.status$.next('error');
    this.lastError$.next(e);
  }

  init(): Observable<void> {
    this.isShareCollection = false;
    return this.status$.pipe(
      first(),
      switchMap((status) => {
        switch (status) {
          //se l'ultima volta ho avuto un errore o non ci ho mai provato => faccio la sync
          case 'error':
          case 'uninitialized': {
            this.status$.next('loading');
            return this.syncRemote().pipe(
              map((response) => {
                this.status$.next('loaded');
                return response;
              }),
              catchError((e) => {
                this.setError(e);
                return of(e);
              })
            );
          }
          default:
            return of(undefined);
        }
      })
    );
  }

  getCollection(uuid: string) {
    return this.collections$.pipe(map((collections) => collections.find((el) => el.uuid === uuid)));
  }

  private syncRemote(): Observable<Collection[]> {
    const updateCollections$ = (collection: Collection[]) => {
      //creo tre array per gestire le tre possibili operazioni (creazione, modifica, eliminazione)
      const collectionsToCreate = collection.filter((local) => local.syncField === CollectionSyncField.TO_CREATE);
      const collectionsToUpdate = collection.filter((local) => local.syncField === CollectionSyncField.TO_UPDATE);
      const collectionsToRemove = collection.filter((el) => el.syncField === CollectionSyncField.TO_DELETE);

      const obsArray: Observable<unknown>[] = [];
      if (collectionsToRemove.length) obsArray.push(this.deleteCollections(collectionsToRemove, true));
      for (const collectionToCreate of collectionsToCreate) {
        obsArray.push(this.createCollection(collectionToCreate, true));
      }
      for (const collectionToUpdate of collectionsToUpdate) {
        obsArray.push(this.updateCollection(collectionToUpdate, true));
      }
      //utilizziamo il concat (e non il fork) perchè lanciando singolarmente le chiamate che poi aggiornano lo stato
      //in parallelo si andrebbe a creare dei problemi di concorrenza
      return obsArray.length ? concat(...obsArray).pipe(toArray()) : of([]);
    };

    return this.fetchCollections().pipe(
      switchMap((remoteCollections) =>
        from(this.cataleanStorage.getRow<Collection[]>(this.COLLECTIONS_STORAGE_KEY)).pipe(
          switchMap((collections: Collection[]) => {
            //se ho collezioni locali (cioè sempre tranne la primissima volta che avvio l'app)
            //aggiorno ciò che ho in locale con quello remoto
            if (collections) {
              return updateCollections$(collections).pipe(
                switchMap((el) => {
                  return this.fetchCollections();
                })
              );
            }
            return of(remoteCollections);
          }),
          map((response) => {
            this.setLocalCollections(response);
            return response;
          })
        )
      ),
      //se non riesco a fare la fetch delle collezioni allora ritorno i dati che ho in locale
      catchError((e) => from(this.cataleanStorage.getRow<Collection[]>(this.COLLECTIONS_STORAGE_KEY)))
    );
  }

  //pulisce la collection dai campi usati solo a FE
  clearCollectionData(collection: Collection): Collection {
    const result = structuredClone(collection);
    if (collection.syncField === CollectionSyncField.TO_CREATE) delete result.uuid; //elimino fake uuid
    delete result.syncField;
    delete result.assets;

    return result;
  }

  fetchCollections() {
    return this.dataleanServiceProvider.getCollections().pipe(
      switchMap((collectionsList) => {
        const collectionsWithResource = collectionsList.map((collection) => {
          collection.assets = this.retrieveResource(collection.assetsRef);
          return collection;
        });
        //merge dati remoti con dati locali (in caso di con errori parziali o dati non sincronizzati)
        return from(this.mergeRemoteColletionWithLocalCollection(collectionsWithResource));
      })
    );
  }

  private setLocalCollections(collections: Collection[]) {
    this.collections$.next(collections);
    this.cataleanStorage.setRow(this.COLLECTIONS_STORAGE_KEY, collections);
  }

  /**
   * diamo per scontato che chi usa questa funzione abbia gestito prima lo storage impostando gli elementi da eliminare
   * con syncField = CollectionSyncField.TO_DELETE
   * @param collections collections da eliminare
   * @param withStorageFlow se passato a true aggiorna lo storage rimuovendo gli elementi che sono stati eliminati
   * @returns
   */
  deleteCollections(collections: Collection[], withStorageFlow: boolean = false) {
    const collectionsUUID: string[] = collections.map((el) => el.uuid);

    if (withStorageFlow) {
      //Aggiorno i dati in storage
      const collections = this.collections$.value;
      for (const collection of collections) {
        if (collectionsUUID.includes(collection.uuid)) {
          collection.syncField = CollectionSyncField.TO_DELETE;
        }
      }

      this.setLocalCollections(collections);
    }

    return this.dataleanServiceProvider.deleteCollections(collectionsUUID).pipe(
      //non mi interessa il catchError perchè prima abbiamo già settato lo stato locale di quelle da eliminare
      //quindi dove si utilizzano le collezioni si devono filtrare per quelle != da TO_DELETE
      switchMap((response) => {
        if (withStorageFlow) {
          //se devo gestire lo storage
          return from(this.cataleanStorage.getRow<Collection[]>(this.COLLECTIONS_STORAGE_KEY)).pipe(
            map((collections) => {
              //aggiorno elimino dall'array gli elementi con gli id indicati in collectionsUUID
              const newCollections = collections.filter((el) => !collectionsUUID.includes(el.uuid));
              this.setLocalCollections(newCollections);
              return response;
            })
          );
        }

        return of(response);
      })
    );
  }

  //elimina una collezione che non è ancora stata sincronizzata
  deleteLocalCollection(collection: Collection) {
    if (collection.syncField === CollectionSyncField.TO_CREATE) {
      const collectionsUpdated = this.collections$.value?.filter((el) => el.uuid !== collection.uuid);
      this.setLocalCollections(collectionsUpdated);
    }
  }

  createCollection(collection: Collection, withStorageFlow: boolean = false): Observable<Collection> {
    collection.syncField = CollectionSyncField.TO_CREATE;
    collection.isPublic = false; //le collezioni create dall'app sono private
    const parsedCollection = this.clearCollectionData(collection);

    return this.dataleanServiceProvider.createCollection(parsedCollection).pipe(
      catchError(() => {
        //Se la richiesta fallisce, mi segno che queta collection è ancora da creare
        collection.syncField = CollectionSyncField.TO_CREATE;
        return of(collection);
      }),
      switchMap((response) => {
        if (withStorageFlow) {
          //se devo gestire lo storage
          return from(this.cataleanStorage.getRow<Collection[]>(this.COLLECTIONS_STORAGE_KEY)).pipe(
            map((collections) => {
              //aggiorno l'elemento e risalvo nello storage (la collection locale ha un fakeUID)
              const indexItem = collections.findIndex((el) => el.uuid === collection.uuid);

              //se era già presente lo aggiorno (significa che un tentativo passato era fallito), altrimenti lo inserisco
              if (indexItem > -1) {
                collections[indexItem] = response;
              } else {
                //la create non ti restituisce gli asset, quindi li andiamo a recupeare
                response.assets = this.retrieveResource(response.assetsRef);
                collections.push(response);
              }

              this.setLocalCollections(collections);
              return response;
            })
          );
        }

        return of(response);
      })
    );
  }

  /**
   * funzione che fa l'update di una collection, se withStorageFlow = true aggiorna automaticamente
   * lo storage con il nuovo dato modificato, in caso di errore viene restituita la vecchia
   * collection ma con il campo syncField = "TO_UPDATE", così alla prossima sincronizzazione
   * si riprova a fare l'update
   * @param collection collezione da aggiornare a BE
   * @param withStorageFlow flag che indica se avviare o meno il flusso automatico di aggiornamento del dato nello storage
   * @returns se ok -> collezione modificata, se ko -> vecchia collezione con syncField = "TO_UPDATE"
   */
  updateCollection(collection: Collection, withStorageFlow: boolean = false): Observable<Collection> {
    const parsedCollection = this.clearCollectionData(collection);
    return this.dataleanServiceProvider.updateCollection(parsedCollection).pipe(
      catchError(() => {
        //Se la richiesta fallisce, mi segno che queta collection è ancora da modificare
        collection.syncField = CollectionSyncField.TO_UPDATE;
        return of(undefined);
      }),
      switchMap((response) => {
        //se la risposta non è undefined allora è andato tutto ok
        const collectionAfterCall = response ? parsedCollection : collection;
        if (withStorageFlow) {
          //se devo gestire lo storage
          return from(this.cataleanStorage.getRow<Collection[]>(this.COLLECTIONS_STORAGE_KEY)).pipe(
            map((collections) => {
              //aggiorno l'elemento e risalvo nello storage
              const indexItem = collections.findIndex((el) => el.uuid === collectionAfterCall.uuid);
              if (indexItem > -1) collections[indexItem] = collection;
              this.setLocalCollections(collections);
              return collectionAfterCall;
            })
          );
        }

        //in caso di errore ritorna la collection con syncField = TO_UPDATE
        //altrimenti ritorna l'elemento passato all'update
        return of(collectionAfterCall);
      })
    );
  }

  private retrieveResource(assetsRef: string[]): Resource[] {
    const result: Resource[] = [];
    for (const ref of assetsRef) {
      const existResource = this.resourceManagr.Resources.find((el) => el.uuid === ref);
      if (existResource) result.push(existResource);
    }
    return result;
  }

  private async mergeRemoteColletionWithLocalCollection(remoteCollections: Collection[]): Promise<Collection[]> {
    const result: Collection[] = [];
    const localCollections = await this.cataleanStorage.getRow<Collection[]>(this.COLLECTIONS_STORAGE_KEY);
    //la getRow se non trova nulla, restituisce stringa vuota quindi al primo accesso non troverò nulla
    //di consegunza non devo fare il merge
    if (typeof localCollections !== 'string') {
      //recupero i dati non sincronizzati
      const localCollectionsNotSync = localCollections?.filter((el) => el.syncField) ?? [];

      //tolgo i dati remoti per metterci quelli locali
      for (const remoteCollection of remoteCollections) {
        const localItem = localCollectionsNotSync.find((el) => el.uuid === remoteCollection.uuid);
        result.push(localItem ? localItem : remoteCollection);
      }

      //inserisco anche i dati che ho creato localmente
      for (const collactionToCreate of localCollectionsNotSync.filter((el) => el.syncField === CollectionSyncField.TO_CREATE))
        result.push(collactionToCreate);

      return result;
    }

    return remoteCollections;
  }

  //aggiunge gli asset indicati nel body nelle collection indicate
  //una volta fatto ri-effettua la fetch delle collection e aggiorna lo storage
  addToCollections(addParams: AlbumUpdateBody) {
    const body: AlbumUpdateBody = {
      albumUUIDs: addParams.albumUUIDs,
    };

    if (addParams.entityUUIDs && addParams.entityUUIDsMode) {
      body.entityUUIDsMode = addParams.entityUUIDsMode;
      body.entityUUIDs = addParams.entityUUIDs;
    }

    if (addParams.entityFilter) {
      body.entityFilter = addParams.entityFilter;
    }

    return this.dataleanServiceProvider.addToCollections(body).pipe(
      switchMap((response) => this.fetchCollections()),
      map((collectionMerged) => {
        if (collectionMerged) this.setLocalCollections(collectionMerged);
      })
    );
  }

  //reset delle collezioni nello storage
  //utile in fase di log-out o log-in per avere una situazione
  //pulita del nuovo utente
  clearCollectionStore(){
    this.setLocalCollections([])
  }
}
