export interface Integration extends CataleanAppConfig {
  type: 'catalean',
  version: number,
  name: string
}
export interface CataleanAppConfig {
  textSearchFields: string[];
  resourceBatchLimit: number;
  autoHideVerticalFilter: boolean;
  downloadLinkIos?: string;
  downloadLinkAndroid?: string;
  downloadLinkElectron?: string;
};