import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CataleanStorageService {
  public readonly STORAGE_RESOURCE_MAP_ITEM_KEY = 'assets';

  constructor() {}

  getLanguage(): Promise<string> {
    return this.getRow('locale');
  }

  async setLanguage(value: string) {
    return await this.setRow('locale', value);
  }

  getResource(remoteResourceUrl: string): Promise<string> {
    return this.getRow(`${this.STORAGE_RESOURCE_MAP_ITEM_KEY}.${remoteResourceUrl}`);
  }

  async removeAllAssetResources() {
    Preferences.keys();
    const res = await Preferences.keys();
    for (const key of res.keys) {
      if (key.includes(this.STORAGE_RESOURCE_MAP_ITEM_KEY)) {
        await Preferences.remove({ key });
      }
    }
    return true;
  }

  async getAllResources(): Promise<Record<string, any>> {
    const localResourcesMap: Record<string, any> = {};
    const res = await Preferences.keys();
    for (const key of res.keys) {
      if (key.startsWith(this.STORAGE_RESOURCE_MAP_ITEM_KEY)) {
        const cleanKey = key.replace(`${this.STORAGE_RESOURCE_MAP_ITEM_KEY}.`, '');
        const resValue = await this.getRow(key)
        try {
          localResourcesMap[cleanKey] = JSON.parse(resValue);
        } catch {
          localResourcesMap[cleanKey] = resValue;
        }
      }
    }
    return localResourcesMap;
  }

  async addOrUpdateResource(remoteResourceUrl: string, resourceObjAsString: string) {
    await this.setRow(`${this.STORAGE_RESOURCE_MAP_ITEM_KEY}.${remoteResourceUrl}`, resourceObjAsString);
  }

  async setRow(key: string, value: any) {
    if (typeof value === 'string') {
      return await Preferences.set({key, value}) 
    }
    return await Preferences.set({key, value: JSON.stringify(value)}) 
  }

  async getRow<T = string>(key: string): Promise<T> {
    const rowData = await Preferences.get({key})
    if (rowData && typeof rowData.value === 'string') {
      try {
        return JSON.parse(rowData.value);
      } catch {
        if(rowData.value === 'undefined') {
          return undefined
        }
        return rowData.value as T;
      }
    }
    return '' as T;
  }
}
