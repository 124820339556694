import { Injectable } from '@angular/core';
import { FilterManagerService } from './filter-manager.service';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { CoreEntity } from 'catalean-models';

@Injectable({
  providedIn: 'root',
})
export class EntityManagerService<T extends CoreEntity> {
  constructor(protected filterService: FilterManagerService) {}
  protected _entities: T[] = [];

  protected filteredEntities$ = combineLatest([
    this.filterService.getSelectedMetroFilter(),
    this.filterService.getActiveHorizontalFilter(),
    this.filterService.getActiveVerticalFilters(),
  ]).pipe(
    debounceTime(1),
    map(([metroFilter, horizontalFilter, verticalFilters]) => {
      return this._entities.filter((p) => {
        if (!p.features.includes(metroFilter.filter.featureId)) {
          return false;
        }
        if (horizontalFilter && !p.features.includes(horizontalFilter.featureId)) {
          return false;
        }
        if (verticalFilters?.length) {
          for (const verticalFilter of verticalFilters) {
            const hasValueForVerticalFilter = verticalFilter.values.some((vfv) => p.features.includes(vfv.featureId));
            if (!hasValueForVerticalFilter) {
              return false;
            }
          }
        }
        //se non ci sono filtri attivi oltre alla metro mi basta che ci sia la chiave che corrisponde all'ultima metro attiva
        return true;
      });
    })
  );


  protected getFilteredEntities(): Observable<T[]> {
    return this.filteredEntities$;
  }

  protected getFilteredEntitiesReplay(): Observable<T[]> {
    return this.filteredEntities$.pipe(shareReplay({refCount: true, bufferSize: 1}))
  }

  protected get Entities(): T[] {
    return this._entities;
  }

  protected mapAndSaveEntites(entitiesArray: T[]) {
    this._entities = entitiesArray.map((e) => {
      if ('search_keys' in e.features) {
        delete e.features.search_keys;
      }
      if ('communityUUID' in e) {
        delete e.communityUUID;
      }
      if ('schemaVersion' in e) {
        delete e.schemaVersion;
      }
      return e;
    });
  }

  protected getEntityById(id): T {
    return this._entities.find((product) => product.uuid === id);
  }

  protected resetEntities() {
    this._entities = [];
  }
}
