export function getImageField(obj: { [key: string]: any }, keys: string[]): string {
  const [key, ...next] = keys;
  if (!next.length)
    return obj[key];

  const innerObj = obj[key];
  if (!innerObj || typeof innerObj != 'object')
    return null; // path is invalid

  return getImageField(innerObj, next);
}

export interface PasswordValidatorResultSet {
  LENGTH: string;
  LOWER: string;
  UPPER: string;
  NUMBER: string;
  SPECIAL: string;
  GENERIC: string;
  MISMATCH: boolean;
}

export const validatePassword = (password: string) : PasswordValidatorResultSet => {
  const lengthCheck = password.length >= 8;
  const lowerCaseLetter = (/[a-z]/.test(password));
  const upperCaseLetter = (/[A-Z]/.test(password));
  const numberCheck = (/[0-9]/.test(password));
  const special = (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password));
  return {
    LENGTH: lengthCheck ? 'success' : 'error',
    LOWER: lowerCaseLetter ? 'success' : 'error',
    UPPER: upperCaseLetter ? 'success' : 'error',
    NUMBER: numberCheck ? 'success' : 'error',
    SPECIAL: special ? 'success' : 'error',
    GENERIC: '',
    MISMATCH: false
  };
}
