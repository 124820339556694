import { Injectable } from '@angular/core';
import { DataleanDataProviderService } from 'catalean-provider';
import { UserSettingsService } from './user-settings-service.service';
import { NetworkStateService } from './network-state-service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthFeedbackService {
  private readonly ACCESS_DATA_STORED_KEY: string = 'accessData';

  constructor(
    private dataleanServiceProvider: DataleanDataProviderService,
    private userSettingsService: UserSettingsService,
    private networkStateService: NetworkStateService
  ) {}

  saveAccessFeedback() {
    let me = this;
    let accessDataArray: any[] = [];
    let accessData = this.getDataForFeedback(this.dataleanServiceProvider.FEEDBACK_TYPE_HAS_ACCESSED_KEY);
    const feedbackSubject = new Subject();
    if (this.networkStateService.NetworkState === 'online' && !window.localStorage.getItem(this.ACCESS_DATA_STORED_KEY)) {
      this.dataleanServiceProvider.saveGeneralFeedback(accessData).subscribe(
        () => {
          feedbackSubject.next('accessedFeedback');
        },
        (error) => {
          feedbackSubject.error(error);
        }
      );
    }
    if (this.networkStateService.NetworkState === 'offline') {
      if (!window.localStorage.getItem(this.ACCESS_DATA_STORED_KEY)) {
        accessDataArray.push(accessData);
        window.localStorage.setItem(this.ACCESS_DATA_STORED_KEY, JSON.stringify(accessDataArray));
      } else {
        accessDataArray = JSON.parse(localStorage.getItem(this.ACCESS_DATA_STORED_KEY));
        accessDataArray.push(accessData);
        window.localStorage.setItem(this.ACCESS_DATA_STORED_KEY, JSON.stringify(accessDataArray));
      }
    }
    if (this.networkStateService.NetworkState === 'online' && window.localStorage.getItem(this.ACCESS_DATA_STORED_KEY)) {
      accessDataArray = JSON.parse(localStorage.getItem(this.ACCESS_DATA_STORED_KEY));
      accessDataArray.push(accessData);
      accessDataArray.forEach((accessData, i) => {
        setTimeout(() => {
          me.dataleanServiceProvider.saveGeneralFeedback(accessData).subscribe(
            () => {
              feedbackSubject.next('accessDataFeedbackPosted');
            },
            (error) => {
              feedbackSubject.error(error);
            }
          );
        }, i * 2000);
      });
      window.localStorage.removeItem(this.ACCESS_DATA_STORED_KEY);
    }
  }

  getDataForFeedback(feedbackType) {
    const data = {
      feedbackType: feedbackType,
      entityUUID: this.userSettingsService.getAuthenticatedUserData().AuthenticationMethods[0].uuid,
      cataleanUUID: environment.catalean.uuid,
      userUUID: this.userSettingsService.getAuthenticatedUserData().Id,
      authDate: this.getDateAndTime(),
    };
    return data;
  }

  getDateAndTime() {
    let newDate = new Date();
    let date = newDate.toLocaleDateString();
    let time = newDate.toLocaleTimeString();
    let dateAndTime = date + ' ' + time;
    return dateAndTime;
  }
}
