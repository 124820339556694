export class AuthenticationToken {

  constructor(private token: string, private expirationTimestamp?: string) {

  }

  get Token() {
    return this.token;
  }

  get ExpirationTimeStamp() {
    return this.expirationTimestamp;
  }

  getPayloadParameter(parameterKey: string): any {
    const payload = this.getTokenPayload();
    return this.decodePayload(payload)[parameterKey];
  }

  private getTokenPayload() {
    if (this.token) {
      const tokenComponents = this.token.split('.');
      if (tokenComponents.length === 3) {
        return tokenComponents[1];
      }
    }
    return undefined;
  }

  private decodePayload(payload: string): any {
    try {
      return JSON.parse(atob(payload));
    } catch (e) {
      return {};
    }
  }
}
