<button class="header-hamburger-button opened" slot="end" (click)="closeHamburgerMenu()">
  <div></div>
  <div></div>
</button>
<div class="button-container">
  <swiper #filterSlides>
    <ng-container *ngIf="filterMapList?.length && visibleFilterUUID">
      <ng-template swiperSlide *ngFor="let level of filterMapList; let i = index">
        <ng-container *ngIf="level[visibleFilterUUID[i]]">
          <button (click)="goBack()" class="button" [ngClass]="{ active: activeFilter?.level === 0}">
            <ng-container *ngIf="i > 0">
              <span><</span>
            </ng-container>
            {{ level[visibleFilterUUID[i]].parent.label }}
          </button>
          <button
            *ngFor="let filter of level[visibleFilterUUID[i]].children"
            (click)="openMetroItem(filter)"
            class="button"
            [ngClass]="{ active: activeFilter?.filter.featureId === filter.featureId }"
          >
            {{ filter.label }}
          </button>
        </ng-container>
      </ng-template>
    </ng-container>
  </swiper>

  <button class="button lingua">{{ 'HAMBURGER_MENU.LANGUAGE' | translate }}</button>
  <button class="button logout" (click)="logOutClick()">{{ 'HAMBURGER_MENU.LOGOUT' | translate }}</button>
  <div *ngIf="true" class="hamburger-additional-items">
    <ng-container [ngTemplateOutlet]="buttonTemplate"></ng-container>
    <ng-container *ngIf="privacyText$ | async as privacy">
      <button class="button privacy" id="open-modal-privacy">
        {{ 'HAMBURGER_MENU.PRIVACY_CREDITS_BUTTON' | translate }}
      </button>
      <ion-modal trigger="open-modal-privacy" class="privacy-modal">
        <ng-template>
          <cat-privacy-modal [text]="privacy" [userDeletion]="userDeletion$ | async"></cat-privacy-modal>
        </ng-template>
      </ion-modal>
    </ng-container>
    <button class="button" *ngFor="let menuItem of additionalMenuItems$ | async" (click)="openExternalLink(menuItem)">
      {{ menuItem.title }}
    </button>
  </div>
</div>
