import { SafeUrl } from "@angular/platform-browser";

export interface Resource {
  uuid?: string,
  url:string
  name?:string;
  bytes?:string,
  assetType?:string,
  format?:string,
  videoDuration?:string,
  tags?: { uuid: string; name: string }[];
  preview?: string
  previewURL?: string;
}

export interface LocalResource extends Omit<Resource, 'url'> {
  url:string | SafeUrl
}
