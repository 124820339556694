import {Inject, Injectable} from '@angular/core';
import {AlertController, LoadingController, Platform} from '@ionic/angular';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {DataleanDataProviderService} from 'catalean-provider';
import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import { CurrentPlatformService } from 'src/app/services/current-platform.service';

declare var cordova;

interface Catalean {
  version: number;
  uuid: string;
  downloadLinkIos: string;
  downloadLinkAndroid: string;
  downloadLinkElectron: string;
  mimeTypeElectron: string;
  androidAutoUpdate: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CataleanVersionControllerService {
  private downloadingResources: any[] = [];

  constructor(
    private dataleanDataService: DataleanDataProviderService,
    @Inject('catalean') private cataleanData: Catalean,
    private platform: CurrentPlatformService,
    private alert: AlertController,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private translatePipe: TranslatePipe,
  ) {
  }

  public checkAppVersion(): Observable<boolean> {
    if (this.platform.is('cordova')) {
      return this.dataleanDataService.getCatalean(this.cataleanData.uuid).pipe(
        map((cataleanData: any) => {
          let isOutdated = false;
          if (cataleanData?.version) {
            isOutdated = this.cataleanData.version < parseInt(cataleanData.version, 10);
          }
          return isOutdated;
        })
      );
    }
    return of(true);
  }

  public showAppUpdateAvailableAlert() {
    this.translateService
      .get(['CHECK_VERSION.APP_UPDATE_AVAILABLE', 'CHECK_VERSION.APP_UPDATE_AVAILABLE_MESSAGE', 'CHECK_VERSION.DOWNLOAD_APP_UPDATE'])
      .subscribe((t) =>
        this.alert
          .create({
            header: t['CHECK_VERSION.APP_UPDATE_AVAILABLE'],
            message: t['CHECK_VERSION.APP_UPDATE_AVAILABLE_MESSAGE'],
            backdropDismiss: false,
            buttons: [
              {
                text: t['CHECK_VERSION.DOWNLOAD_APP_UPDATE'],
                handler: () => {
                  this.downloadApp();
                  return false;
                },
              },
            ],
          })
          .then((alert) => alert.present())
      );
  }

  private downloadApp(): void {
    let downloadLink: string;
    let downloadOptions: { electronDirect: boolean; androidFallback?: boolean };
    if (this.platform.is('android')) downloadLink = this.cataleanData.downloadLinkAndroid;
    else if (this.platform.is('ios')) downloadLink = this.cataleanData.downloadLinkIos;
    else if (this.platform.is('electron')) {
      downloadLink = this.cataleanData.downloadLinkElectron;
      downloadOptions = {
        electronDirect: true,
      };
    }

    if (downloadLink) {
      this.doDownload(downloadLink, downloadOptions).subscribe(
        () => console.log('Installer launched'),
        (err) => {
          if (!this.platform.is('electron')) {
            this.autoupdateError();
          } else {
            setTimeout(() => window.close(), 5000);
          }
          console.error(err);
        },
        () => {
          if (this.platform.is('electron')) {
            window.close();
          } else if (this.platform.is('cordova')) {
            if (navigator['app']) {
              navigator['app'].exitApp();
            } else if (navigator['device']) {
              navigator['device'].exitApp();
            } else {
              cordova.plugins.exit();
            }
          }
        }
      );
    }
  }

  private autoupdateError() {
    this.alertCtrl.getTop().then((alert) => alert?.dismiss());
    this.alertCtrl
      .create({
        header: this.translatePipe.transform('AUTOUPDATE.ERROR_HEADER'),
        message: this.translatePipe.transform('AUTOUPDATE.ERROR_MESSAGE'),
        buttons: [
          {
            text: this.translatePipe.transform('AUTOUPDATE.OK'),
            handler: () => navigator['app'].exitApp(),
          },
        ],
        backdropDismiss: false,
      })
      .then((alert) => alert.present());
  }

  doDownload(
    uri: string,
    options?: {
      androidFallback?: boolean;
      electronDirect?: boolean;
    }
  ): Observable<void> {
    const downloadSubject = new Subject<void>();
    // if (this.platform.is('cordova')) {
    //   this.downloadingResources.push(uri);
    //   if (this.platform.is('android')) {
    //     if(this.cataleanData.androidAutoUpdate) {
    //       const fileName = uri.split('/').pop();
    //       const target = `${cordova.file.externalDataDirectory}Downloads/${fileName}`;
    //       if (!options?.androidFallback) {
    //         this.downloadAndOpen(uri, target, 'application/vnd.android.package-archive', downloadSubject);
    //       } else {
    //         this.fileTransfer
    //           .create()
    //           .download(uri, target)
    //           .then(
    //             () => {
    //               this.downloadingResources = this.downloadingResources.filter((url) => url !== uri);
    //               downloadSubject.complete();
    //             },
    //             (error) => {
    //               this.downloadingResources = this.downloadingResources.filter((url) => url !== uri);
    //               window.open(uri, '_blank');
    //               downloadSubject.error(error);
    //             }
    //           );
    //       }
    //     } else {
    //       this.iab.create(this.cataleanData.downloadLinkAndroid, '_system', {location: 'no'});
    //     }
    //   }
    //   if (this.platform.is('ios')) {
    //     this.loadingCtrl.dismiss();
    //     const browser = this.iab.create(uri, '_system', {location: 'no'});
    //     if (browser) {
    //       browser.on("loadstart").subscribe(event => {
    //         console.log("starting loader of page ", uri);
    //       });
    //       browser.on("loaderror").subscribe(event => {
    //         console.error("error on opening  ", uri);
    //       });
    //       browser.on("exit").subscribe(event => {
    //         console.info("exiting  ", uri);
    //       });
    //       browser.on("loadstop").subscribe(event => {
    //         this.downloadingResources = this.downloadingResources.filter((url) => url !== uri);
    //         downloadSubject.complete();
    //       });
    //     } else {
    //       this.downloadingResources = this.downloadingResources.filter((url) => url !== uri);
    //       downloadSubject.complete();
    //     }
    //   }
    //   if (this.platform.is('electron')) {
    //     if (!options?.electronDirect) {
    //       saveAs(uri.replace(/->/g, '-%3E'), uri.split('/').pop());
    //       this.downloadingResources = this.downloadingResources.filter((url) => url !== uri);
    //       downloadSubject.complete();
    //     } else {
    //       const fileName = uri.split('/').pop();
    //       const target = `${cordova.file.dataDirectory}${fileName}`.replace(/\\/g, '/');
    //       console.log(cordova.file.dataDirectory);

    //       this.downloadAndOpen(uri, target, this.cataleanData.mimeTypeElectron, downloadSubject);
    //     }
    //   }
    // } else {
    //   window.open(uri, '_blank');
    //   this.downloadingResources = this.downloadingResources.filter((url) => url !== uri);
    //   downloadSubject.complete();
    // }
    return downloadSubject;
  }

  private downloadAndOpen(uri: string, target: string, mimeType: string, subject?: Subject<any>) {
    // this.translateService
    //   .get('DOWNLOAD_NEW_APP')
    //   .subscribe((message) => this.loadingCtrl.create({message}).then((load) => load.present()));
    // this.fileTransfer
    //   .create()
    //   .download(uri, target)
    //   .then(
    //     (fileEntry: FileEntry) => {
    //       this.downloadingResources = this.downloadingResources.filter((url) => url !== uri);
    //       this.loadingCtrl
    //         .getTop()
    //         .then((loader) => loader.dismiss())
    //         .catch((err) => console.error(err));
    //       console.log(fileEntry);

    //       let urlToOpen = fileEntry.nativeURL;
    //       if (!urlToOpen || this.platform.is('electron')) {
    //         urlToOpen = fileEntry.fullPath;
    //       }
    //       this.fileOpener
    //         .open(urlToOpen, mimeType)
    //         .then(() => subject.complete())
    //         .catch((err) => subject.error(err));
    //     },
    //     (error) => {
    //       this.downloadingResources = this.downloadingResources.filter((url) => url !== uri);
    //       this.loadingCtrl
    //         .getTop()
    //         .then((loader) => loader.dismiss())
    //         .catch((err) => console.error(err));
    //       subject.error(error);
    //     }
    //   );
  }
}
