import { ProductManagerService } from './services/product-manager.service';
import { ResourceManagerService } from './services/resource-manager.service';
import { FeatureManagerService } from './services/feature-manager.service';
import { StructureManagerService } from './services/structure-manager.service';
import { EnvironmentConfiguration } from 'catalean-models';
import { UrlBuilderService } from './services/url-builder.service';
import { Observable, zip, Subject, of, throwError, forkJoin } from 'rxjs';
import { SmartHttpModuleService } from './services/smart-http-module.service';
import { Injectable, Inject } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CataleanLocalizationService } from 'catalean-localization';
import { ContentManagerService } from './services/content-manager.service';
import { OperationPermissionManagerService } from './services/operation-permission-manager.service';

@Injectable({
  providedIn: 'root',
})
export class CataleanCacheDataProviderService {
  public readonly CATALEAN_CACHE_FILTER = 'filter';
  public readonly CATALEAN_CACHE_PRODUCTS = 'product';
  private readonly NOT_AVAILABLE_CACHE = 'NOT_AVAILABLE_CACHE';
  private activeLanguage: string;

  constructor(
    public cataleanLocalizationService: CataleanLocalizationService,
    @Inject('env') private environmentSettings: EnvironmentConfiguration,
    private smartHttpModule: SmartHttpModuleService,
    private productManager: ProductManagerService,
    private resourceManager: ResourceManagerService,
    private featureManager: FeatureManagerService,
    private structureManager: StructureManagerService,
    private contentManager: ContentManagerService,
    private operationPermissionManager: OperationPermissionManagerService
  ) {
    this.cataleanLocalizationService.ObserveActiveLang.subscribe((lan: string) => (this.activeLanguage = lan));
  }

  // FIXME better name
  // web version only
  initializeJsons(userUUID?: string, skipCache?: boolean): Observable<boolean> {
    const finishSubject = new Subject<boolean>();
    zip(
      !skipCache ? this.getJsonVersion(this.CATALEAN_CACHE_PRODUCTS, this.activeLanguage, userUUID) : of('0'),
      !skipCache ? this.getJsonVersion(this.CATALEAN_CACHE_FILTER, this.activeLanguage, userUUID) : of('0')
    )
      .pipe(
        switchMap((versionArray) => {
          if (!(versionArray[0] && versionArray[1])) {
            return of([]);
          }
          localStorage.setItem('lastProductVersion', versionArray[0]);
          return forkJoin([
            this.getProductsJson(versionArray[0], this.activeLanguage, userUUID),
            this.getFiltersJson(versionArray[1], this.activeLanguage, userUUID),
          ]);
        }),
        catchError((err) => {
          return throwError(this.NOT_AVAILABLE_CACHE);
        })
      )
      .subscribe(
        (jsonArray) => {
          if (!(jsonArray[0] && jsonArray[1])) {
            finishSubject.next(false);
          }
          this.productManager.mapAndSaveProducts(jsonArray[0].products);
          this.featureManager.mapAndSaveFeatures([...jsonArray[1].filters, ...(jsonArray[1].featureValues ?? [])]);
          this.contentManager.mapAndSaveContent(jsonArray[0].content);
          this.resourceManager.mapAndSaveResources(jsonArray[0].resources ?? []);
          this.structureManager.mapAndSaveStructures(jsonArray[0].structures);
          this.operationPermissionManager.mapAndSaveOperationPermission(jsonArray[0].operationPermission ?? [])
          finishSubject.next(true);
        },
        (error) => {
          finishSubject.error(error);
        }
      );

    return finishSubject.asObservable();
  }

  getProductsJson(version: string, locale: string, userUUID?: string): Observable<any> {
    if (version === '0') {
      const requestUrl = new UrlBuilderService(this.environmentSettings.templateUrl + 'parse/v3')
        .withQueryParam('uuidParameterType', 'MAP_CONTAINER_UUID') // TODO
        .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
        .withQueryParam('uuid', '41350706-b3db-4f0d-bb30-60d34ec5ae58')
        .withQueryParam('templateType', 'CATALEAN_2_PRODUCTS')
        .withQueryParam('includeAllProducts', 'false');
      const finalRequestUrl = requestUrl.build();
      return this.smartHttpModule.get(finalRequestUrl);
    } else {
      const requestUrl = new UrlBuilderService(
        this.environmentSettings.cataleanCacheUrl + this.CATALEAN_CACHE_PRODUCTS + '/version/' + version
      )
        .withQueryParam('locale', locale) // TODO
        .withQueryParam('accountId', this.environmentSettings.accountId);
      if (userUUID) {
        requestUrl.withQueryParam('applicationUserUUID', userUUID);
      }

      const finalRequestUrl = requestUrl.build();
      return this.smartHttpModule.get<any>(finalRequestUrl);
    }
  }

  /**
   * @param  {string} version
   * @returns Observable
   *
   * Recupera la consolidation-date da catalean-cache per il controllo dell'aggioranmento dei file
   */
  getProductsConsolidationDate(version: string): Observable<any> {
    const requestUrl = new UrlBuilderService(
      `${this.environmentSettings.cataleanCacheUrl}${this.CATALEAN_CACHE_PRODUCTS}/version/${version}/consolidation-date`
    ).withQueryParam('accountId', this.environmentSettings.accountId);

    const finalRequestUrl = requestUrl.build();
    return this.smartHttpModule.get<any>(finalRequestUrl).pipe(map((data) => data.lastConsolidationDate));
  }

  getFiltersJson(version: string, locale: string, userUUID?: string): Observable<any> {
    if (version === '0') {
      const requestUrl = new UrlBuilderService(this.environmentSettings.templateUrl + 'parse/v3')
        .withQueryParam('uuidParameterType', 'MAP_CONTAINER_UUID') // TODO
        .withQueryParam('organizationUUID', this.environmentSettings.organizationUUID)
        .withQueryParam('uuid', '41350706-b3db-4f0d-bb30-60d34ec5ae58')
        .withQueryParam('templateType', 'CATALEAN_FILTERS')
        .withQueryParam('includeAllProducts', 'false');
      const finalRequestUrl = requestUrl.build();
      return this.smartHttpModule.get<any>(finalRequestUrl);
    } else {
      const requestUrl = new UrlBuilderService(
        this.environmentSettings.cataleanCacheUrl + this.CATALEAN_CACHE_FILTER + '/version/' + version
      )
        .withQueryParam('locale', locale) // TODO
        .withQueryParam('accountId', this.environmentSettings.accountId);
      if (userUUID) {
        requestUrl.withQueryParam('applicationUserUUID', userUUID);
      }

      const finalRequestUrl = requestUrl.build();
      return this.smartHttpModule.get(finalRequestUrl);
    }
  }

  getJsonVersion(endpoint: string, locale: string, userUUID?: string): Observable<string> {
    const requestUrl = new UrlBuilderService(this.environmentSettings.cataleanCacheUrl + endpoint + '/currentVersion')
      .withQueryParam('locale', locale)
      .withQueryParam('accountId', this.environmentSettings.accountId);
    if (userUUID) {
      requestUrl.withQueryParam('applicationUserUUID', userUUID);
    }
    const finalRequestUrl = requestUrl.build();
    return this.smartHttpModule.get(finalRequestUrl, 'text');
  }
}
