import {Component, Inject, Input, OnInit} from '@angular/core';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import {ModalController} from '@ionic/angular';
import { CurrentPlatformService } from 'src/app/services/current-platform.service';


@Component({
  selector: 'cat-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styles: [],

})
export class PrivacyModalComponent implements OnInit {

  @Input() text: string;
  @Input() userDeletion?: {address:string, subject:string, body:string};
  version: string;

  constructor(private modalCtrl: ModalController,
              @Inject('version') private envVersion: string,
              private platform: CurrentPlatformService) { }

  ngOnInit(): void {
    if(this.platform.is('cordova') && !this.platform.is('electron')){
      App.getInfo().then((appInfo) => {
        this.version = appInfo.version
      }).catch(err => {
        this.version = this.envVersion;
      });
    } else {
      this.version = this.envVersion;
    }

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  openCataleanWebsite() {
    Browser.open({
      url: 'https://www.catalean.com'
    })
  }

  deleteUserMail(){
    Browser.open({
      url: "mailto:" + this.userDeletion.address + "?subject=" + this.userDeletion.subject + "&body=" + this.userDeletion.body
    })
  }
}
