import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'product-grid',
    loadChildren: () => import('./pages/product-grid/product-grid.module').then((m) => m.ProductGridPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'metro',
    loadChildren: () => import('./pages/metro/metro.module').then((m) => m.MetroPageModule),
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then((m) => m.ProductDetailPageModule),
  },
  {
    path: 'search-results', //matcha se passo la stringa vuota
    loadChildren: () => import('./pages/search-page/search-results.module').then((m) => m.SearchResultsPageModule),
  },
  {
    path: 'search-results/:text',
    loadChildren: () => import('./pages/search-page/search-results.module').then((m) => m.SearchResultsPageModule),
  },
  {
    path: 'communications',
    loadChildren: () => import('./pages/contents/contents.module').then((m) => m.ContentsModule),
    data: {contentPath: "/communications/", configName: "comunicazioni"}
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/services/service.module').then((m) => m.ServicePageModule),
  },
  {
    path: 'events-news',
    loadChildren: () => import('./pages/contents/contents.module').then((m) => m.ContentsModule),
    data: {contentPath: "/events-news/", configName: "events"}
    // loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsPageModule),
  },
  {
    path: 'worksheet',
    loadChildren: () => import('./pages/worksheet/worksheet.module').then((m) => m.WorksheetPageModule),
  },
  {
    path: 'catalean-share',
    loadChildren: () => import('./catalean-share/catalean-share.module').then((m) => m.CataleanSharePageModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'corporate',
    loadChildren: () => import('./pages/corporate/corporate.module').then( m => m.CorporatePageModule)
  },
  {
    path: 'downloads',
    loadChildren: () => import('./pages/downloads/downloads.module').then( m => m.DownloadsPageModule)
  },
  {
    path: 'collections',
    loadChildren: () => import('./pages/collections/collections.module').then( m => m.CollectionsPageModule)
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
