import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrentPlatformService } from 'src/app/services/current-platform.service';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class NetworkStateService {
  public networkState: any;
  isOnline = new BehaviorSubject<boolean>(true);

  constructor(private platform: CurrentPlatformService) {
    if(this.platform.is('cordova')) {
      Network.addListener('networkStatusChange', status => {
        if(status.connected) {
          this.isOnline.next(true);
        } else {
          this.isOnline.next(false);
        }
      })
    }
  }

  get NetworkState() {
    return this.networkState;
  }

  set NetworkState(networkState) {
    this.networkState = networkState;
  }
}
