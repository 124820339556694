import { Resource } from "../models";

export interface Collection {
  uuid: string;
  name: string;
  isPublic: boolean;
  updateDate: Date;
  assets?: Resource[];
  assetsRef: string[];
  //campo usato solo in app per gestire i dati offline
  //(se non è presente allora la collection è uguale a quella remota)
  syncField?: CollectionSyncField 
}

type ObjectValues<T> = T[keyof T];
export const CollectionSyncField = {
  TO_UPDATE: "TO_UPDATE",
  TO_CREATE: "TO_CREATE",
  TO_DELETE: "TO_DELETE",
} as const
export type CollectionSyncField = ObjectValues<typeof CollectionSyncField>