import { Injectable } from '@angular/core';
import { FeatureValue, Filter } from 'catalean-models';
import { FilterManagerService } from './filter-manager.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureManagerService {
  constructor(private filterManager: FilterManagerService) {}

  private featureValues: FeatureValue[] = [];

  get Features(): FeatureValue[] {
    return this.featureValues;
  }

  mapAndSaveFeatures(featuresArray: FeatureValue[]) {
    if (Array.isArray(featuresArray)) {
      this.featureValues = [...featuresArray];
      const rootFilter = featuresArray.find((feature) => feature.filterOptions.isRoot);
      if (rootFilter) {
        this.filterManager.setRootFilter(this.recursiveFilterTransformation(rootFilter));
      }
    }
  }

  recursiveFilterTransformation(featureValue: FeatureValue): Filter {
    let label = featureValue.label;
    if (typeof label !== 'string' && label !== undefined && (label as any[]).length) {
      label = (label[0] as any).value;
    }
    const updatedObject: Filter = {
      name: featureValue.name,
      label,
      iconURL: featureValue.iconURL,
      featureId: featureValue.uuid,
      entityType: featureValue.type,
      order: featureValue.order,
      ...featureValue.filterOptions,
      children: [],
      imageURL: featureValue.imageURL,
    };
    if (featureValue.featureValues?.length) {
      updatedObject.children = featureValue.featureValues.map(this.recursiveFilterTransformation, this);
    }
    return updatedObject;
  }

  getFeatureValue(id): FeatureValue | undefined {
    return this.featureValues.find((singleFeature) => singleFeature.uuid == id);
  }
}
