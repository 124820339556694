import { PaginationInfo, Parts } from 'catalean-models';

export class UrlBuilderService {
  protected requestUrl: string = '';

  constructor(requestUrl) {
    this.requestUrl = requestUrl;
  }

  withQueryParam(queryParam: string, value: string) {
    const concatenationCharacter = this.requestUrl.includes('?') ? '&' : '?';
    this.requestUrl += concatenationCharacter + queryParam + '=' + value;
    return this;
  }

  withPaginationInfo(paginationInfo: PaginationInfo) {
    return this.withQueryParam('numberOfPage', paginationInfo.nextPage.toString())
      .withQueryParam('numberOfPageElements', paginationInfo.numberOfElementsOnPage.toString());
  }

  withFullTextSearchFilter(fullTextSearchString: string) {
    this.requestUrl += '&q=' + encodeURIComponent(fullTextSearchString) + '&searchFields=name,description';
    return this;
  }

  withStructureUUIDsFilter(structureUUIDs: string[]) {
    let structureUUIDsStringValue = '';
    structureUUIDs.forEach((structureUUID, index) => {
      structureUUIDsStringValue += structureUUID;
      if (index < structureUUIDs.length - 1) {
        structureUUIDsStringValue += ',';
      }
    });

    this.requestUrl += '&structureUUID=' + structureUUIDsStringValue;
    return this;
  }

  withParts(parts: Parts[] | string) {
    if (Array.isArray(parts)) {
      for (const part of parts) {
        this.requestUrl += this.withParts(part);
      }
      return this;
    } else {
      this.requestUrl +=  '&parts=' + parts;
      return this;
    }
  }

  withSortClause(sortingField: string, sortingDirection: string) {
    this.requestUrl += '&sortBy=' + encodeURIComponent(sortingField + '#' + sortingDirection);
    return this;
  }

  withSortClauses(sortingClauses: Map<string, string>) {
    let appendString = '&sortBy=';
    if (sortingClauses.size > 0) {
      sortingClauses.forEach((sortingDirectionValue: string, sortingFieldKey: string) => {
        this.requestUrl += appendString + encodeURIComponent(sortingFieldKey + '#' + sortingDirectionValue);
        appendString = ',';
      });
    }

    return this;
  }

  build(): string {
    return this.requestUrl;
  }
}
